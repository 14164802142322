import * as api from 'services/api';
import store from 'store';

const {dispatch} = store;


// const REQUEST_EDS_SERVER_LIST = 'REQUEST_EDS_SERVER_LIST';
const REQUEST_SIGN_DATA = 'REQUEST_SIGN_DATA';
const CHECK_SIGN_DATA = 'CHECK_SIGN_DATA';

// export function requestEDSServerList(){
//     return api.get('authorise/eds/serverList', REQUEST_EDS_SERVER_LIST, dispatch);
// }


export function requestSignData() {
    return api.get('authorise/eds/sign', REQUEST_SIGN_DATA, dispatch);
}

export async function checkSignData(options: SignData){
    const result = await api.post(' authorise/eds', options, CHECK_SIGN_DATA, dispatch);

    if(result instanceof Error || result?.error) {
        throw new Error('Виникла помилка при створенні сесії.');
    }

    return result;
}
export type EDSActions = typeof REQUEST_SIGN_DATA | typeof CHECK_SIGN_DATA
