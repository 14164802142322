import React, { Component } from 'react';
import setComponentsId from 'helpers/setComponentsId';
import { requestSignData, checkSignData } from 'actions/eds';
import { getAuth } from 'actions/auth';
import DefaultLoginLayout from './components/DefaultLoginLayout'
import {withConfig, WithConfigProps} from "../../components/Config";

interface Props extends WithConfigProps {
    setId: SetIdFunc;
    dataToSign: string;
}

type State = { error: null | string, token: null | string }

class LoginPage extends Component<Props, State> {
    state = { error: null, token: null };

    signDataAndLogin = async (signData, cb = () => {}) => {
        try {
            await checkSignData(signData);
            await cb();
        } catch(e) {
            console.error(e.message);
            throw e;
        } finally {
            await getAuth();
        }
    }

    handleSelectKey = (signData, cb) => {
        let iteration = 0;

        const {maxReLoginAttempts} = this.props.config;

        const execute = () => this.signDataAndLogin(signData, cb).catch((e) => {
            iteration += 1;
            if (iteration <= maxReLoginAttempts) {
                return execute();
            }
            throw e;
        });

        return execute();
    };

    componentDidMount() {
        requestSignData().then(({ token }) => {
            this.setState(state => ({ ...state, token: token || 'internal-data-test' }));
        });
    }

    render() {
        return (
            <DefaultLoginLayout
                setId={this.props.setId}
                onSelectKey={this.handleSelectKey}
                token={this.state.token}
            />
        );
    }
    static defaultProps = {
        setId: setComponentsId('login'),
    };
}

export default withConfig(LoginPage as any);
