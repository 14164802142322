import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';

import {
    withStyles,
    Paper,
    FormControl,
    Typography,
    FormHelperText,
    Theme
} from '@material-ui/core';
import {createStyles} from "@material-ui/core/styles";
import {translate} from "react-translate";

const styles = (theme: Theme) => createStyles({
    root: {
        marginTop: 24,
        // marginBottom: 24,
        display: 'block'
    },
    paper: {
        border: '2px dashed #808080'
    },
    errored: {
        border: '2px dashed rgba(255,0,0,1)'
    },
    dropZone: {
        outline: 'none',
        padding: '20px 120px',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            padding: '10px 20px'
        }
    },
    title: {
        fontSize: 18,
        lineHeight: '24px',
        wordBreak: 'break-word',
        [theme.breakpoints.down('xs')]: {
            fontSize: 15
        }
    },
    link: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    dropNewLink: {
        textDecoration: 'underline',
        cursor: 'pointer',
        fontSize: '12px',
        lineРeight: '16px'
    },
    subtitle: {
        fontSize: '12px',
        lineРeight: '26px',
        opacity: 0.5
    },
    dropZoneActive: {
        background: '#cdd7e3'
    },
    uploadButton: {
        marginLeft: 16
    },
    uploadButtonContainer: {
        marginBottom: 20,
        '@media screen and (max-width: 425px)': {
            padding: 0,
            marginBottom: 15
        }
    },
    raw: {
        padding: 20,
        fontSize: 18,
        textAlign: 'left',
        '& ul, ol, p, a': {
            margin: 0,
            marginBottom: 15
        },
        '& ul, ol': {
            paddingLeft: 15,
            '& li': {
                marginBottom: 10
            }
        },
        '& a': {
            color: '#009be5'
        }
    },
    fontReg: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '20px'
    }
});

interface FileInputFieldProps extends BaseProps{
    id: string,
    onChange(value: File): void,
    value: File,
    margin: string,
    disabled: boolean,
    error: boolean,
    helperText: string,
    accept: string[],
    label: string,
    fileLimit: number; // in mb
}
interface FileInputFieldState{
    active:boolean,
    stateError: { message: string } | null
}

class FileInputField extends Component<FileInputFieldProps, FileInputFieldState> {
    state = { active: false, stateError : null };
    static defaultProps = {
        fileLimit: 1,
    }

    onDrop = <T extends File>(acceptedFiles: T[], rejectedFiles:T[]) => {
        const { onChange, t } = this.props;

        if (rejectedFiles.length && !acceptedFiles.length) {
            this.setState(state => ({ ...state, stateError: { message: t('FileSizeLimit') } }));
            onChange && onChange(null);
            return;
        }

        this.setState(state => ({ ...state, stateError: null }));

        onChange && onChange(acceptedFiles[0]);
    };

    setActive = (active: boolean) => state => this.setState(state => ({ ...state, active }));

    renderBody = ({ getRootProps, getInputProps }: {
        getRootProps:() => React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
        getInputProps:() => React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>}) =>
    {
        const { active, stateError } = this.state;
        const { t, classes, value, error, helperText, accept, fileLimit } = this.props;

        const rootProps = getRootProps();
        const inputProps = getInputProps();

        return (
            <FormControl
                error={!!error || !!stateError}
                className={classes.root}
            >
                <Paper
                    elevation={0}
                    className={classNames(classes.paper, {
                        [classes.dropZoneActive]: active,
                        [classes.errored]: !!error || !!stateError
                    })}
                >
                    <div {...rootProps} className={classes.dropZone}>
                        <input {...inputProps} />
                        {value
                            ? (
                                <React.Fragment>
                                    <Typography variant="body1" className={classes.title}>
                                        {t('DropedFile')}
                                    </Typography>
                                    {value.name ? (
                                        <Typography variant="body1" className={classes.title}>
                                            {value.name}
                                        </Typography>
                                    ) : null}
                                    <Typography variant="body1" className={classes.dropNewLink}>
                                        {t('DropNewFile')}
                                    </Typography>
                                </React.Fragment>
                            )
                            : (
                                <React.Fragment>
                                    <Typography variant="body1" className={classes.title}>
                                        {t('DropFiles', {
                                            link: <span className={classes.link}>{t('UploadFiles')}</span>
                                        })}
                                    </Typography>
                                    <Typography variant="body2" className={classes.subtitle}>
                                        {t('DropFilesLimits', { size: fileLimit, accept: accept.join(',') })}
                                    </Typography>
                                </React.Fragment>
                            )}
                    </div>
                </Paper>
                {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
                {error ? (error as any).message : null}
                {stateError ? (
                    <FormControl error={true}>
                        <FormHelperText>{stateError.message}</FormHelperText>
                    </FormControl>
                ) : null}
            </FormControl>
        );
    };


    render() {
        const { classes, accept, fileLimit } = this.props;
        return (
            (
                <Dropzone
                    multiple={false}
                    accept={accept}
                    maxSize={fileLimit * 1024 * 1024}
                    // @ts-ignore
                    activeClassName={classes.dropZoneActive}
                    onDrop={this.onDrop}
                    onDragEnter={this.setActive(true)}
                    onDragLeave={this.setActive(false)}
                >
                    {this.renderBody}
                </Dropzone>
            )
        );
    }
}

const styled = withStyles(styles)(FileInputField);
export default translate('Elements')(styled);