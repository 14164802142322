import React from 'react';
import setComponentsId from 'helpers/setComponentsId';
import {translate} from 'react-translate';
import StaticPageContent from 'components/StaticPageContent';

interface AboutProps extends BaseProps{  }

const fields = ['text', 'text', 'text', 'text', 'text'];

const About: React.FC<AboutProps> = ({ setId, t }) => (
    <StaticPageContent
        setId={setId}
        t={t}
        fields={fields}
    />
);


About.defaultProps = {
    setId: setComponentsId('about')
};

export default translate('About')(About);
