import React, { Component } from 'react';
import { translate } from 'react-translate';
import setComponentsId from 'helpers/setComponentsId';
import { Link } from 'react-router-dom';

import {
    Card,
    CardContent,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    withStyles
} from '@material-ui/core';

import { Videocam } from '@material-ui/icons';

import styles from 'assets/jss';
import {WithTheme} from 'themes';

interface TopHeaderLayoutProps extends BaseProps, WithTheme {
    footer?: React.ReactNode
}

class TopHeaderLayout extends Component<TopHeaderLayoutProps, {}> {
    state = { openVideo: false };

    toggleVideoDialog = () => this.setState({ openVideo: !this.state.openVideo });

    render() {
        const { classes, t, children, setId, theme } = this.props;
        const { openVideo } = this.state;
        return (
            <Card
                className={classes.topHeaderLayout}
                id={setId('')}
            >
                <CardContent
                    className={classes.header}
                    id={setId('content-header')}
                >
                    {theme.useVideoHelp
                        ? (
                            <Grid
                                container={true}
                                id={setId('header-container')}
                            >
                                <Grid
                                    item={true}
                                    xs={6}
                                    id={setId('logo')}
                                >
                                    <Link to="/" id={setId('link-logo')}>
                                        <img className={classes.logo} src={theme.headerImage} alt={t('TITLE')} style={theme.headerImageStyle} />
                                    </Link>
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={6}
                                >
                                    <Button
                                        className={classes.videoHelpBtn}
                                        onClick={this.toggleVideoDialog}
                                        id={setId('open-video-button')}
                                    >
                                        <Videocam />
                                        <span
                                            className={classes.videoLinkText}
                                        >
                                            &nbsp; {t('VIDEO_HELP')}
                                        </span>
                                    </Button>
                                </Grid>
                            </Grid>
                        )
                        : (
                            <Link to="/" id={setId('link-logo')}>
                                <img className={classes.logo} src={theme.headerImage} alt={t('TITLE')} style={theme.headerImageStyle} />
                            </Link>
                        )}
                </CardContent>
                <CardContent
                    className={classes.body}
                    id={setId('content')}
                >
                    {children}
                    <Dialog
                        open={openVideo}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        onClose={this.toggleVideoDialog}
                        id={setId('video-dialog')}
                        className={classes.dialog}
                        fullWidth={true}
                    >
                        <DialogTitle
                            id={setId('video-dialog-title')}
                            className={classes.dialogContentWrappers}
                        >
                            {t('VIDEO_HELP')}
                        </DialogTitle>
                        <DialogContent
                            id={setId('video-dialog-content')}
                            className={classes.dialogContentWrappers}
                        >
                            <iframe
                                id={setId('video-dialog-iframe')}
                                className={classes.videoFrame}
                                title="video"
                                src="https://www.youtube.com/embed/bD83fM28x3I"
                                frameBorder="0"
                                allowFullScreen={true}
                            />
                        </DialogContent>
                    </Dialog>
                </CardContent>
            </Card>
        );
    }
    static defaultProps = {
        setId: setComponentsId('top-header'),
    };
}




const styled = withStyles(styles, {
    withTheme: true
})(TopHeaderLayout as any);
export default translate('Layout')(styled);
