import React from 'react';

interface GoogleTagManagerProps extends BaseProps {
    GTM_KEY: string;
}

const script = (id: string) => `
    (function(w,d,s,l,i){
        var f=d.getElementById(s);
        f.async=true;
        f.src='https://www.googletagmanager.com/gtag/js?id='+i;
        w[l]=[];
        function gtag(){w[l].push(arguments);}
        gtag('js', new Date());
        gtag('config', i);
    })(window,document,'gaRequest','dataLayer','${id}');
`;

class GoogleTagManager extends React.Component<GoogleTagManagerProps, {}> {
    componentDidMount() {
        const gtmScriptNode = document.getElementById('react-google-tag-script');
        eval((gtmScriptNode as HTMLElement).textContent!); // eslint-disable-line no-eval
    }

    render() {
        return (
            <React.Fragment>
                <div id={'react-google-tag-script'}>
                    <script
                        dangerouslySetInnerHTML={{// eslint-disable-line react/no-danger
                            __html: script(this.props.GTM_KEY)
                        }}
                    />
                </div>
                {this.props.children}
            </React.Fragment>
        );
    }
}


export default GoogleTagManager;
