import React, { Component } from 'react';
import {DatePicker} from 'material-ui-pickers';
import {translate} from 'react-translate';
import setComponentsId from 'helpers/setComponentsId';
import { today } from 'helpers/humanDateFormat';
import moment from 'moment';
import 'moment/locale/uk';

moment.locale('uk');

interface CustomDatePickerProps extends BaseProps{
    onChange(argument: string): void,
    date: Date|string,
    incomingFormat: string,
    id: number|string,
    label: string,
    error: string|boolean,
    helperText: string,
    minDate: Date|string|object,
    maxDate: Date|string|object,
    margin: "normal" | "none" | "dense" | undefined
}

const defaultFormat  = 'DD.MM.YYYY';
const defaultMinDate = moment('01.01.1900', defaultFormat);
const defaultMaxDate = today() as moment.Moment;

class CustomDatePicker extends Component<CustomDatePickerProps> {
    getIncomingDate = (date: Date|string|object) =>
        moment(date, this.props.incomingFormat).format(defaultFormat);

    state = {
        date:     this.props.date ? moment(this.props.date, this.props.incomingFormat) : today(),
        dateText: this.props.date ? this.getIncomingDate(this.props.date) : '',
        error:    this.props.error || '',
        minDate:  this.props.minDate ? this.getIncomingDate(this.props.minDate) : defaultMinDate.format(defaultFormat),
        maxDate:  this.props.maxDate ? this.getIncomingDate(this.props.maxDate) : defaultMaxDate.format(defaultFormat)
    };

    onChange = (date: moment.Moment) => {
        const { onChange, incomingFormat } = this.props;
        this.setState({ date, dateText: date.format(defaultFormat) }, () =>
            onChange(date.format(incomingFormat)));
    };

    validateDate = (value: string, update?: boolean) => {
        const { t, onChange } = this.props;
        const { minDate, maxDate } = this.state;
        let error = '';
        if (!moment(value, defaultFormat, true).isValid()) {
            error = t('FORMAT_ERROR');
        } else {
            error = '';
            if (moment(value, defaultFormat).toDate() < moment(minDate, defaultFormat).toDate()) {
                error = t('MIN_DATE_ERROR', { date: minDate });
            } else if (moment(value, defaultFormat).toDate() > moment(maxDate, defaultFormat).toDate()) {
                error = t('MAX_DATE_ERROR', { date: maxDate });
            } else if (update) {
                this.onChange(moment(value, defaultFormat));
            }
        }
        if (!value.length && update) {
            onChange(value);
        }
        this.setState({ dateText: value, error });
    }

    onInputChange = ({ target: { value } }: { target: { value:string } }) => this.validateDate(value, true);

    componentWillReceiveProps(nextProps: CustomDatePickerProps) {
        const { date, minDate, maxDate, error, helperText } = nextProps;
        this.setState({
            date:    moment(date || new Date()),
            minDate: minDate ? this.getIncomingDate(minDate) : defaultMinDate.format(defaultFormat),
            maxDate: maxDate ? this.getIncomingDate(maxDate) : defaultMaxDate.format(defaultFormat)
        });
        if ((date as string).length > 0) {
            this.validateDate(this.getIncomingDate(date));
        } else if (error) {
            this.setState({ error: typeof error === 'string' ? error : helperText });
        } else {
            this.setState({ dateText: '' });
        }
    }

    render() {
        const {t, label, id, helperText, margin} = this.props;
        const {date, dateText, error, minDate, maxDate} = this.state;
        const pickerId = this.props.setId ? this.props.setId(`date-picker ${id}`) : setComponentsId('date-picker')(` ${id} `);

        return (
            <DatePicker
                label={label || t('LABEL')}
                margin={margin}
                format={defaultFormat}
                placeholder={moment(date).format(defaultFormat)}
                cancelLabel={t('CANCEL')}
                helperText={typeof error === 'string' && !!error ? error : helperText}
                onChange={this.onChange}
                error={!!error}
                keyboard={true}
                autoOk={true}
                id={pickerId}
                minDate={moment(minDate, defaultFormat)}
                maxDate={moment(maxDate, defaultFormat)}
                InputProps={{
                    value:    dateText,
                    onChange: this.onInputChange
                }}
                value=''
            />
        );
    }
    static defaultProps = {
        setId:          undefined as any,
        date:           '',
        incomingFormat: 'YYYY-MM-DD',
        id:             '',
        label:          '',
        error:          '',
        helperText:     '',
        minDate:        '',
        maxDate:        '',
        margin:         'normal'
    };
}

export default translate('DatePicker')(CustomDatePicker);
