import { READERS, KEY_TYPES } from "./consts";
import { PossibleThemeName } from "./themes"
import {ISignerConfig, ISignerProxyConfig} from "./services/eds/common";
import defaultsDeep from "lodash/defaultsDeep";
import MobileDetect from "mobile-detect";

export interface IConfig {
  "FORCE_REGISTER"?: boolean;
  "agentLink": string;
  "maxReLoginAttempts": number;
  "BACKEND_URL"?: string;
  "APP_TITLE": string;
  "PERSONAL_DATA_AGREEMENT": boolean;
  "ONLINE_HELP": boolean;
  "GOV_ID": boolean;
  "SHOW_PHONE": boolean;
  "SHOW_PHONE_CONFIRM": boolean;
  "GTM_KEY"?: string;
  "SENTRY_DSN"?: string;
  "eds": ISignerConfig;
  "APP_NAME": PossibleThemeName;
  "ENVIRONMENT": "prod" | string;
  "APP_ENV": "prod" | string;
  "IIT": {
    FILEKEY_EXTENSIONS: string[];
  },
  "providers": {
    "KEY_TYPE": KEY_TYPES;
    "KEY_READER": READERS;
    "GOV_ID": boolean;
  },
  "isMobile": boolean;
}

const createConfig = (userAgent: string) => {
  let extConfig: IConfig = {} as any;
  let isLoaded = false;
  const md = new MobileDetect(userAgent);

  return {
    config: new Proxy(extConfig, {
      get: function (target, prop) {
        if(!isLoaded) {
          throw new Error('config still not loaded');
        }

        // @ts-ignore
        return Reflect.get(...arguments);
      }
    }) as IConfig,
    load: async (config: Partial<IConfig>) => {
      if(isLoaded) throw new Error('can not load config.again');

      const proxySettings: ISignerProxyConfig = defaultsDeep(config.eds.proxySettings, {
          useProxy: false,
          anonymous: true,
          address: '',
          port: '3128',
          user: '',
          password: '',
          savePassword: false
        });

      extConfig = Object.freeze({
        ...config,
        BACKEND_URL: process.env.BACKEND_URL || config.BACKEND_URL || window.location.origin,
        SENTRY_DSN: process.env.REACTSCRIPTS_SENTRY_DSN || config.SENTRY_DSN,
        eds: {
          ...config.eds,
          proxySettings,
        },
        IIT: defaultsDeep(config.IIT, {
          FILEKEY_EXTENSIONS: [".jks", ".pfx", ".pk8", ".zs2", ".dat"]
        }),
        providers: {
          ...config.providers,
          KEY_TYPE: localStorage.getItem("APP_KEY_TYPE") || config?.providers?.KEY_TYPE,
          KEY_READER: localStorage.getItem("APP_KEY_READER") || config?.providers?.KEY_READER,
        },
        isMobile: !!md.mobile(),
      }) as IConfig;

      isLoaded = true;

      return extConfig;
    }
  }
};

let extConfig = createConfig(window.navigator.userAgent);

export default extConfig.config as IConfig;

export const load = async () => {
  try {
    const req = await fetch('/config.json');
    const loaded = await req.json();
    const config = await extConfig.load(loaded);

    console.info("Config loaded successful");

    return config;
  } catch(e) {
    console.error(e);
    throw e
  }
}
