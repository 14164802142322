import React from 'react';
import setComponentsId from 'helpers/setComponentsId';
import {translate} from 'react-translate';
import StaticPageContent from 'components/StaticPageContent';

const fields = ['br', 'text', 'br', 'strong', 'text', 'br', 'strong', 'text', 'br', 'strong', 'text', 'br', 'strong', 'text', 'br'];

interface FaqProps extends BaseProps { }

const Faq: React.FC<FaqProps> = ({ setId, t }) => (
    <StaticPageContent
        setId={setId}
        t={t}
        fields={fields}
    />
);

Faq.defaultProps = {
    setId: setComponentsId('faq')
};

export default translate('FAQ')(Faq);
