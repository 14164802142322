import React from 'react';
import setComponentsId from 'helpers/setComponentsId';
import {translate} from 'react-translate';
import {Link} from 'react-router-dom';

import {
    Card,
    CardContent,
    Grid,
    Typography,
    withStyles
} from '@material-ui/core';

import styles from 'assets/jss';
import {WithTheme} from "../themes";

type LeftSidebarLayoutProps = BaseProps & WithTheme;

const LeftSidebarLayout: React.FC<LeftSidebarLayoutProps> = ({ classes, t, children, setId, theme }) => (
    <Card
        className={classes.leftSidebarLayout}
        id={setId('')}
    >
        <Grid
            container={true}
            id={setId('grid')}
        >
            <Grid
                item={true}
                xs={12}
                sm={5}
                id={setId('content-wrap')}
            >
                <CardContent
                    className={classes.header}
                    id={setId('header-content')}
                >
                    <Link to="/" id={setId('link-logo')}>
                        <img className={classes.logo} src={theme.headerImage} style={theme.headerImageStyle} alt={t('TITLE')}/>
                    </Link>
                    <Typography
                        variant="subheading"
                        gutterBottom={true}
                        className={classes.subheader}
                        id={setId('title')}
                    >
                        {t('REGISTER_TITLE')}
                    </Typography>
                </CardContent>
            </Grid>
            <Grid
                item={true}
                xs={12}
                sm={7}
                id={setId('grid-2')}
            >
                <CardContent
                    className={classes.body}
                    id={setId('content')}
                >
                    {children}
                </CardContent>
            </Grid>
        </Grid>
    </Card>
);


LeftSidebarLayout.defaultProps = {
    setId:    setComponentsId('left-side-bar'),
    children: ''
};

const styled = withStyles(styles, {
    withTheme: true
})(LeftSidebarLayout as any);
export default translate('Layout')(styled);
