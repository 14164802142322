import App from 'containers/App';
import Faq from 'containers/Faq';
import Terms from 'containers/Terms';
import About from 'containers/About';
import Policy from 'containers/Policy';
import setId from 'helpers/setComponentsId';
import {CustomTheme} from "../themes";
import TwoFactorAuth from 'pages/TwoFactorAuth';
import {RouteProps} from "react-router-dom";

export type RouteDefinition = RouteProps & Partial<{
    setId: SetIdFunc;
    isActive: (theme: CustomTheme) => boolean;
}>

const available: RouteDefinition[] = [
    {
        path: '/terms',
        component: Terms,
        setId: setId('terms'),
        isActive: theme => !theme.hideTermsLink
    },
    {
        path:      '/2fa',
        component: TwoFactorAuth,
        setId:     setId('twoFactorAuth')
    },
    {
        path:      '/faq',
        component: Faq,
        setId:     setId('faq')
    },
    {
        path:      '/about',
        component: About,
        setId:     setId('about')
    },
    {
        path:      '/policy',
        component: Policy,
        setId:     setId('policy')
    },
    {
        path: '/',
        component: App,
        setId: setId('app')
    },
];

export const routes = (theme: CustomTheme) => available.filter(({ isActive }) => isActive ? isActive(theme) : true);
