
/**
 * Caas
 */

var defaults = {
    cadesType: "CAdESXLong",
};

export const INTERACTIONS = { Agent: 1, Server: 2 };

export default class Caas {

    KeyStoreTypeEnum = { keyStorePath: 1, base64Data: 2, binaryData: 3 };
    SessionDataTypeEnum = { base64Data: 1, binaryData: 2 };
    SignatureDataTypeEnum = { base64Data: 1, binaryData: 2 };
    CaasIneractionModeEnum = INTERACTIONS;
    CaasKeyTypeEnum = { File: 0, PKCS11ActiveMode: 1, PKCS11PassiveMode: 2, MobileId: 3 };

    isCaasActive = false;

    headers() {
        var res = {
            'Content-Type': 'application/json',
        };

        if (this.CaasIneractionMode == this.CaasIneractionModeEnum.Server) {
           res['Authorization'] = this.config.caasAuth;
        }

        return res;
    }

    baseUrl() {
        var res = "";
        switch (this.CaasIneractionMode) {
            case this.CaasIneractionModeEnum.Agent:
                res = this.config.caasAgentUrl;
                break;
            case this.CaasIneractionModeEnum.Server:
                res = this.config.caasUrl;
                break;
        }
        return res;
    }

    constructor(httpClient, config = null, mode = INTERACTIONS.Agent) {
        this.httpClient = httpClient;
        this.config = config;

        this._events = {};

        Object.freeze(this.KeyStoreTypeEnum);
        Object.freeze(this.SessionDataTypeEnum);
        Object.freeze(this.SignedDataTypeEnum);
        Object.freeze(this.CaasIneractionModeEnum);
        Object.freeze(this.CaasKeyTypeEnum);

        this.debug = (!config.environment || config.environment == 'dev');

        this.CaasIneractionMode = mode;

        this.start();
    }

    /**
     * Event support
     * Supported Events: "caas_active_state_changed",
     */

    on(name, listener) {
        if (!this._events[name]) {
            this._events[name] = [];
        }

        this._events[name].push(listener);
    }

    removeListener(name, listenerToRemove) {
        if (!this._events[name]) {
            return;
        }

        const filterListeners = (listener) => listener !== listenerToRemove;

        this._events[name] = this._events[name].filter(filterListeners);
    }

    emit(name, data) {
        if (!this._events[name]) {
            return;
        }

        const fireCallbacks = (callback) => {
            callback(data);
        };

        this._events[name].forEach(fireCallbacks);
    }

    /**
     * 1. Checks if Caas active on start. 2. Checks Caas Activity every 5 sec (CaasIneractionMode == Agent only).
     */
    async start() {
        await this.checkCaasActive();

        if (this.CaasIneractionMode != this.CaasIneractionModeEnum.Agent) {
            return;
        }

        return await new Promise(resolve => {
            const interval = setInterval(() => {
                this.checkCaasActive();
            }, 5000)
        });
    }

    /**
     * Generate API call result
     * @param {Object} value
     * @param {Number} status - HTTP status code
     * @param {String} message - Text message. Result of HTTP call.
     * @param {String} failureCause - Text message. Extended Error description.
     */
    result(value, status, message, failureCause = "") {
        return { value: value, status: status, message: message, failureCause: failureCause };
    }

    /**
     * Check if API call was successfull
     * @param {Object} result - HTTP status code
     */
    success(result) {
        return !!result && !!result.status && result.status == 200;
    }

    /**
     * checks if CaaS is alive
     * See: https://docs.cipher.kiev.ua/pages/viewpage.action?pageId=8618293
     */
    async checkCaasActive() {
        var currentActive = this.isCaasActive;
        var newActive;

        try {
            let { payload, status } = await this.callApi({
                method: 'GET',
                urlTemplate: 'status'
            });

            newActive = status == 200;
        } catch (error) {
            newActive = false;
        }

        this.isCaasActive = newActive;

        if (currentActive != newActive) {
            this.emit("caas_active_state_changed", {
                detail: {
                    isCaasActive: newActive
                }
            });
        }

        return this.isCaasActive;
    }

    /**
     * Контроль состояния сервера.
     * See: https://docs.cipher.kiev.ua/pages/viewpage.action?pageId=8618293
     */
    async getCaasStatus() {
        let { payload, status } = await this.callApi({
            method: 'GET',
            urlTemplate: 'status'
        });

        return (!!payload.message) ? payload.message : 'Failed to get status';
    }

    /**
     * При использовании Агента отобразить выбор файла
     */
    async showKeyContainerChooser() {
        let { payload, status } = await this.callApi({
            method: 'PUT',
            urlTemplate: 'ui/keyContainerChooser'
        });

        return !!payload ? payload : { filePath: "" };

    }
    /**
     * Получение списка поддерживаемых КПЭДУ/(А)ЦСК
     * See: https://docs.cipher.kiev.ua/pages/viewpage.action?pageId=8618757
     */
    async getSupportedAuthorities() {
        let { payload, status } = await this.callApi({
            method: 'GET',
            urlTemplate: 'certificateAuthority/supported'
        });

        const authorities = (!payload.ca) ? null :
            payload.ca.reduce(function (map, obj) {
                map[obj.id] = obj.name;
                return map;
            }, {});

        return this.result((authorities) ? authorities : null, status, payload.message);
    }

    /**
     * Создание сессии
     * See: https://docs.cipher.kiev.ua/pages/viewpage.action?pageId=8618663
     */
    async createSession() {
        this.sessionId = null;

        let res = await this.callApi({
            method: 'POST',
            urlTemplate: 'ticket'
        });

        const { payload, status } = res;

        // * success
        if (!payload || !payload.ticketUuid) {
            throw new Error(`[CAAS]: broken response`);
        }

        this.sessionId = payload.ticketUuid;

        return this.result(!!this.sessionId ? this.sessionId : null, status, payload.message);
    }

    /**
     * Удаление сессии
     * See: https://docs.cipher.kiev.ua/pages/viewpage.action?pageId=8618665
     */
    async deleteSession() {

        if (!this.sessionId) {
            return this.result(null, null, "No open session exists");
        }

        let { payload } = await this.callApi({
            method: 'DELETE',
            urlTemplate: 'ticket/{uuid}'
        });

        if (!!payload.ticketUuid) {
            this.sessionId = null;
        }

        return this.result(!!payload.ticketUuid ? payload.ticketUuid : null, null, payload.message);
    }

    /**
     * Установка параметров сессии
     * See: https://docs.cipher.kiev.ua/pages/viewpage.action?pageId=8618667
     */
    async setSessionOptions(opts = {}) {
        if (!this.sessionId) {
            return this.result(null, null, "No open session exists");
        }

        //opts.cadesType = (!opts.cadesType) ? defaults.cadesType : opts.cadesType;

        let { payload, status } = await this.callApi({
            method: 'PUT',
            urlTemplate: 'ticket/{uuid}/option'
        }, {
            payload: opts
        });

        // * data returned on success
        if (!!payload.settedOptions) {
            this.log(payload.settedOptions);
        }

        return this.result(payload.settedOptions, status, payload.message);
    }

    /**
     * Загрузка ключевого контейнера сессии
     * See: https://docs.cipher.kiev.ua/pages/viewpage.action?pageId=8619004
     * @param {Object} key - (File path / PKCS#11 path)/ (Base64 encoded data / Binary data)
     * @param {Number} format - Keystore type. One of the KeyStoreTypeEnum values
     */
    async setKey(key, keyStoreType = this.KeyStoreTypeEnum.base64Data) {
        if (!this.sessionId) {
            return this.result(null, null, "No open session exists");
        }

        if (!key) {
            return this.result(null, null, "No key specified");
        }

        let keyData = {};

        switch (keyStoreType) {
            case this.KeyStoreTypeEnum.keyStorePath:
                keyData.keyStorePath = key;
                break;
            case this.KeyStoreTypeEnum.base64Data:
                keyData.base64Data = key;
                break;
            case this.KeyStoreTypeEnum.binaryData:
                keyData.base64Data = btoa(key);
                break;
            default:
                return this.result(null, null, "Unknown key store type specified.");
        }

        let { payload, status } = await this.callApi({
            method: 'PUT',
            urlTemplate: 'ticket/{uuid}/keyStore'
        }, {
            payload: keyData
        });

        return this.result(null, status, payload.message);
    }

    /**
     * Получение данных о ключевом контейнере (котейнер должен быть предварительно загружен)
     * See: https://docs.cipher.kiev.ua/pages/viewpage.action?pageId=8619290
     * @param {string} pass - Key container password
     */
    async getKeyData(pass) {
        if (!this.sessionId) {
            return this.result(null, null, "No open session exists");
        }

        if (pass == '' || !pass) {
            return this.result(null, null, "No password supplied");
        }

        let { payload, status } = await this.callApi({
            method: 'PUT',
            urlTemplate: 'ticket/{uuid}/keyStore/verifier'
        }, {
            payload: { keyStorePassword: pass }
        });


        return this.result(payload, status, payload.message);
    }


    /**
     * Получение данных сертификата
     * See: https://docs.cipher.kiev.ua/pages/viewpage.action?pageId=8618732
     * @param {string} pass - Key container password
     * @param {string} keyType - Possible values: signature / keyAgreement
     */
    async getSertificateInfo(pass, keyType = "signature") {
        if (!this.sessionId) {
            return this.result(null, null, "No open session exists");
        }

        if (pass == '' || !pass) {
            return this.result(null, null, "No password supplied");
        }

        let { payload, status } = await this.callApi({
            method: 'PUT',
            urlTemplate: 'ticket/{uuid}/keyStore/certificate/info/{keyType}'
        }, {
            payload: { keyStorePassword: pass },
            keyType: keyType
        });

        return this.result(payload, status, payload.message);
    }

    /**
     * Загрузка данных сессии
     * See: https://docs.cipher.kiev.ua/pages/viewpage.action?pageId=8618672
     * @param {*} data
     * @param {Number} format - Session data type. One of the SessionDataTypeEnum values
     */
    async setSessionData(data, sessionDataType = this.SessionDataTypeEnum.base64Data) {
        if (!this.sessionId) {
            return this.result(null, null, "No open session exists");
        }

        if (data == '' || !data) {
            return this.result(null, null, "No data supplied");
        }

        let sessionData = {};

        switch (sessionDataType) {
            case this.SessionDataTypeEnum.base64Data:
                sessionData.base64Data = data;
                break;
            case this.SessionDataTypeEnum.binaryData:
                sessionData.base64Data = btoa(data);
                break;

            default:
                return this.result(null, null, "Unknown session data type specified.");
        }

        let { payload, status } = await this.callApi({
            method: 'POST',
            urlTemplate: 'ticket/{uuid}/data'
        }, {
            payload: sessionData
        });

        return this.result(null, status, payload.message);
    }

    /**
     * Создание ЭП для ранее загруженных данных сессии и ключевого контейнера сессии
     * See: https://docs.cipher.kiev.ua/pages/viewpage.action?pageId=8618684
     * @param {string} pass - Key password
     */
    async signData(pass) {
        if (!this.sessionId) {
            return this.result(null, null, "No open session exists");
        }

        if (pass == '' || !pass) {
            return this.result(null, null, "No password supplied");
        }

        let { payload, status } = await this.callApi({
            method: 'POST',
            urlTemplate: 'ticket/{uuid}/ds/creator'
        }, {
            payload: { keyStorePassword: pass }
        });

        return this.result(payload, status, payload.message);
    }

    /**
     * Получение результата создания ЭП
     * See: https://docs.cipher.kiev.ua/pages/viewpage.action?pageId=8618686
     */
    async getSignatureCreationStatus() {
        if (!this.sessionId) {
            return this.result(null, null, "No open session exists");
        }

        let { payload, status } = await this.callApi({
            method: 'GET',
            urlTemplate: 'ticket/{uuid}/ds/creator'
        });

        return this.result(payload, status, !!payload && !!payload.message ? payload.message : null, !!payload && !!payload.failureCause ? payload.failureCause : null);
    }

    /**
     * Получение подписанных данных (application/json)
     * See: https://docs.cipher.kiev.ua/pages/viewpage.action?pageId=8618690
     */
    async getBase64SignedData() {
        if (!this.sessionId) {
            return this.result(null, null, "No open session exists");
        }

        let { payload, status } = await this.callApi({
            method: 'GET',
            urlTemplate: 'ticket/{uuid}/ds/base64SignedData'
        });

        const data = (!!payload.base64Data) ? payload.base64Data : null;

        return this.result(data, status, payload.message);
    }

    /**
     * Получение подписанных данных (application/octet-stream)
     * See: https://docs.cipher.kiev.ua/pages/viewpage.action?pageId=8618692
     */
    async getBinarySignedData() {
        if (!this.sessionId) {
            return this.result(null, null, "No open session exists");
        }

        const opts = { responseType: 'application/octet-stream' };
        let { payload, status } = await this.callApi({
            method: 'GET',
            urlTemplate: 'ticket/{uuid}/ds/signedData'
        });

        const data = (!!payload) ? payload : null;

        return this.result(data, status, payload.message);
    }

    /**
     * Получение данных ЭП (application/json)
     * See: https://docs.cipher.kiev.ua/pages/viewpage.action?pageId=8618696
     */
    async getBase64SignatureData() {
        if (!this.sessionId) {
            return this.result(null, null, "No open session exists");
        }

        let { payload, status } = await this.callApi({
            method: 'GET',
            urlTemplate: 'ticket/{uuid}/ds/base64Data'
        });

        const data = (!!payload && !!payload.base64Data) ? payload.base64Data : null;
        var message = (!!payload && !!payload.message) ? payload.message : null;

        return this.result(data, status, message);
    }

    /**
     * Получение данных ЭП (application/octet-stream)
     * See: https://docs.cipher.kiev.ua/pages/viewpage.action?pageId=8618698
     */
    async getBinarySignatureData() {
        if (!this.sessionId) {
            return this.result(null, null, "No open session exists");
        }

        let { payload, status } = await this.callApi({
            method: 'GET',
            urlTemplate: 'ticket/{uuid}/ds/data'
        }, {
            responseType: 'application/octet-stream'
        });
        const data = (!!payload) ? payload : null;

        return this.result(data, status, payload.message);
    }

    /**
     * Загрузка данных ЭП
     * See: https://docs.cipher.kiev.ua/pages/viewpage.action?pageId=8618700
     * @param {*} data - Signature data.
     * @param {Number} format - signature data type. One of the SignedDataTypeEnum values
     */
    async setsignatureData(data = null, signatureDataType = this.SignatureDataTypeEnum.base64Data) {

        if (!this.sessionId) {
            return this.result(null, null, "No open session exists");
        }

        if (data == '' || !data) {
            return this.result(null, null, "No Signature data supplied");
        }

        let signatureData = {};

        switch (signatureDataType) {
            case this.SignatureDataTypeEnum.base64Data:
                signatureData.base64Data = data;
                break;
            case this.SignatureDataTypeEnum.binaryData:
                signatureData.base64Data = btoa(data);
                break;

            default:
                return this.result(null, null, "Unknown session data type specified.");
        }

        let { payload, status } = await this.callApi({
            method: 'POST',
            urlTemplate: 'ticket/{uuid}/ds/data'
        }, {
            payload: signatureData
        });

        return this.result(null, status, payload.message);
    }

    /**
     * Проверка ЭП.
     * See: https://docs.cipher.kiev.ua/pages/viewpage.action?pageId=8618708
     */
    async startSignatureCheck() {
        if (!this.sessionId) {
            return this.result(null, null, "No open session exists");
        }

        let { payload, status } = await this.callApi({
            method: 'POST',
            urlTemplate: 'ticket/{uuid}/ds/verifier'
        }, {
            contentType: "text/plain"
        });

        return this.result(payload, status, !!payload && !!payload.message ? payload.message : null);
    }

    /**
     * Получение результата проверки ЭП
     * See: https://docs.cipher.kiev.ua/pages/viewpage.action?pageId=8618710
     */
    async getSignatureCheckResult() {
        if (!this.sessionId) {
            return this.result(null, null, "No open session exists");
        }

        let { payload, status } = await this.callApi({
            method: 'GET',
            urlTemplate: 'ticket/{uuid}/ds/verifier'
        });

        return this.result(
            !!payload && !!payload.verifyResults ? payload.verifyResults : null,
            status,
            !!payload && !!payload.message ? payload.message : null,
            !!payload && !!payload.failureCause ? payload.failureCause : null
        );
    }

    /**
     * Получение списка подключенных защищенных носителей
     * See: https://docs.cipher.kiev.ua/pages/viewpage.action?pageId=8618777
     */
    async getConnectedTokens() {
        let { payload, status } = await this.callApi({
            method: 'GET',
            urlTemplate: 'token/connected'
        });

        return this.result(
            payload,
            status,
            !!payload && !!payload.message ? payload.message : null,
            null
        );
    }

    /**
     * Подписание случайных данных для дальнейшего использования на стороне сервера (cadesType: "CAdESXLong", signatureType: "attached",)
     * @param {*} authority - Authority Id
     * @param {*} keyData - Key data
     * @param {*} keyStoreType - one of KeyStoreTypeEnum values
     * @param {*} keyStorePassword - Key password
     */
    async signDataForLogin(authority, keyData, keyStoreType, keyStorePassword) {
        var res = await this.createSession();
        if (!this.success(res)) return res;

        var randomData = btoa(Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15));
        res = await this.setSessionData(randomData);
        if (!this.success(res)) {
            await this.deleteSession();
            return res;
        }

        const opts = {
            caId: authority,
            cadesType: "CAdESXLong",
            signatureType: "attached",
            dataToSignQualifier: "notSignedBefore",
        };

        res = await this.setSessionOptions(opts);
        if (!this.success(res)) {
            await this.deleteSession();
            return res;
        }

        res = await this.setKey(keyData, keyStoreType);
        if (!this.success(res)) {
            await this.deleteSession();
            return res;
        }

        res = await this.signData(keyStorePassword);
        if (!this.success(res)) {
            await this.deleteSession();
            return res;
        }

        try {
            do {
                res = await this.getSignatureCreationStatus();
                if (res.status == 202) {
                    await new Promise((resolve, reject) => setTimeout(resolve(), 3000));
                } else {
                    break;
                }
            } while (true);
        } catch (error) {
            await this.deleteSession();
            return this.result(null, null, error.message);
        }

        if (!this.success(res)) {
            await this.deleteSession();
            return res;
        }

        res = await this.getBase64SignatureData();

        await this.deleteSession();

        return res;
    }

    async signDataForLoginWithBinaryKey(authority, keyData, keyStorePassword) {
        return await this.signDataForLogin(authority, keyData, this.KeyStoreTypeEnum.binaryData, keyStorePassword)
    }

    async signDataForLoginWithKeyStorePath(authority, keyStorePath, keyStorePassword) {
        return await this.signDataForLogin(authority, keyStorePath, this.KeyStoreTypeEnum.keyStorePath, keyStorePassword)
    }

    /**
     * Проверка прикреплённой подписи
     * @param {*} signedData - Data in Base64 format
     */
    async checkAttachedSignature(signedData) {
        var res = await this.createSession();
        if (!this.success(res)) {
            await this.deleteSession();
            return res;
        }

        const opts = {
            cadesType: "CAdESXLong",
            signatureType: "attached",
        };

        res = await this.setSessionOptions(opts);
        if (!this.success(res)) {
            await this.deleteSession();
            return res;
        }

        res = await this.setsignatureData(signedData);
        if (!this.success(res)) {
            await this.deleteSession();
            return res;
        }

        res = await this.startSignatureCheck();
        if (!this.success(res)) {
            await this.deleteSession();
            return res;
        }

        res = await this.getSignatureCheckResult();

        await this.deleteSession();

        return res;
    }

    getAvailableKeyTypes() {
        var result = {};
        switch (this.CaasIneractionMode) {
            case this.CaasIneractionModeEnum.Server:
                result[this.CaasKeyTypeEnum.File] = "[Файл на диску]";
                //result[this.CaasKeyTypeEnum.MobileId] = "Мобільний ЕП (MobileID)";

                break;
            case this.CaasIneractionModeEnum.Agent:
                result[this.CaasKeyTypeEnum.File] = "[Файл на диску]";
                //result[this.CaasKeyTypeEnum.MobileId] = "Мобільний ЕП (MobileID)";
                result[this.CaasKeyTypeEnum.PKCS11ActiveMode] = "[PKCS#11 пристрої] – активний режим";
                result[this.CaasKeyTypeEnum.PKCS11PassiveMode] = "[PKCS#11 пристрої] – пасивний режим";

                break;
        }
        return result;
    }


    /**
     * call CaaS API using `route` specification and data provided
     * @param {*} route
     * @param {*} data - data.responseType, data.contentType, data.payload, data.baseUrl
     */
    async callApi(route, data = {}) {
        let response;
        let payload;
        let start = new Date();

        // * output debug info
        this.log(route);

        const relative_path = this.renderTemplateString(route.urlTemplate, data);

        const url = (!!data.baseUrl ? data.baseUrl : this.baseUrl()) + relative_path;

        const responseType = (data && !!data.responseType) ? data.responseType : 'json';
        this.log(`responseType: ${responseType}`);

        let params = {
            headers: this.headers(),
            responseType: responseType,
            mode: "cors",
            retry: 1,
            throwHttpErrors: false,
        };

        if (data && !!data.contentType) {
            params.headers['Content-Type'] = data.contentType;
        }

        try {
            switch (route.method) {
                case 'GET':
                    response = await  this.httpClient.get(url, params);
                    break;

                case 'DELETE':
                    response = await  this.httpClient.delete(url, params);
                    break;

                case 'PUT':
                case 'POST':
                    if (!!data && !!data.payload) {
                        // * define payload position
                        if (responseType == 'json') {
                            params.json = data.payload;
                        } else {
                            params.body = data.payload;
                        }

                        const payload_string = JSON.stringify(data.payload);
                        this.log('payload length: ' + payload_string.length);
                        this.log('payload: ' + payload_string.substr(0, 100) + ' ... ' + payload_string.substr(payload_string.length - 50, 50));
                    }

                    if (route.method == 'POST') {
                        response = await  this.httpClient.post(url, params);
                    } else {
                        // * PUT
                        response = await  this.httpClient.put(url, params);
                    }

                    break;
            }
        } catch (error) {
            //=> 'Internal server error ...'
            response = error;
            this.log(error);
        }

        if (responseType != 'json') {
            this.log(response);
        }

        const status = (!!response.status) ? response.status : null

        try {
            payload = response.body || await (response.json && response.json()) || null;
        } catch(e) {
            console.group("[CAAS]")
            console.error(e);
            console.error(response);
            console.groupEnd();
        }

        if(payload === null || response instanceof Error) {
            console.error(`[CAAS]: failed [${url}]: `, response.message || response);
        }

        // * put to console
        this.log(`path: ${relative_path}][status: ${status}] [` + (new Date() - start).toString() + ' ms]');

        this.log(payload);

        return {
            payload,
            status
        };
    }

    /**
     * render string by template and data to replace template variables
     * @param {string} template
     * @param {object} data array to replace variables inside template
     */
    renderTemplateString(template, data = {}) {
        if (!data.uuid) data.uuid = this.sessionId;

        for (let key in data) {
            template = template.replace(new RegExp('{' + key + '}', "g"), data[key]);
        }

        this.log('template: ' + template);
        return template;
    }

    /**
     * check if string is base64 encoded
     * @param {string} str
     */
    isBase64(str) {
        let base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
        return base64regex.test(str);
    }

    /**
     * log and show debug output
     * @param  {...any} args
     */
    log(...args) {
        // * debug
        if (this.debug) {
            if (args.length == 1) {
                console.log(args[0]);
            } else {
                console.log(args);
            }
        }
    }

    forceLog(...args) {
        // * output to console
        if (args.length == 1) {
            console.log(args[0]);
        } else {
            console.log(args);
        }
    }
}
