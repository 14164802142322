import moment from 'moment';

export default function humanDateFormat(dateString: string){
    return moment(dateString).format('DD.MM.YYYY');
}

export function humanDateTimeFormat(dateString: string){
    return moment(dateString).format('DD.MM.YYYY HH:mm');
}

export function dateToMoment(birthday: any): any {
    let time = birthday;
    const elements = birthday.split('/');

    if (elements.length === 3) {
        time = moment();
        time.date(elements[0]);
        time.months(elements[1] - 1);
        time.year(elements[2]);
    }

    return time;
}

export function today(format = '') {
    return format ? moment(new Date()).format(format) : moment(new Date());
}

export function fourteenYearsAgo(format = '') {
    const date = (today() as moment.Moment).subtract(14, 'years');
    return format ? date.format(format) : date;
}
