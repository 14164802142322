import Caas from './caas.js';
import config from './config';
import {EventEmitter} from "eventemitter3";
import {EVENTS} from "./common";

export const DEFAULT_AGENT_LINK = config.agentLink;
export const EVENT_TYPES = EVENTS;

function readFileAsync(file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsBinaryString(file);
    })
}

var Defaults = {
    authority: "testCipherCa",
}

class UndefinedKeyTypeError extends Error {

    constructor(type) {
        super(`undefined key type: ${type} of type ${typeof type}`);
    }
}

export default class Signer {

    constructor() {
        this.ee = new EventEmitter();
    }

    /**
     * @param transport - ky for front or got for nodejs
     */
    init(transport){
        this.caas = new Caas(transport, config);
        this.caas.on("caas_active_state_changed",
            (e) => {
                if (e.detail.isCaasActive) {
                    this.ee.emit(EVENTS.SET_ACTIVE);
                } else {
                    this.ee.emit(EVENTS.SET_DISABLED);
                }
            });
    }

    LoginParameters = {
        loginMode: null,

        authority: null,

        keyFile: null,
        keyFilePath: null,

        connectedTokens: null,
        selectedToken: null,

        keyPassword: null,
    }

    get isFileMode(){
        return this.LoginParameters.loginMode === this.caas.CaasKeyTypeEnum.File;
    }

    get isHardwareMode(){
        return !this.isFileMode;
    }

    /**
     * @private
     * @returns {boolean|boolean}
     */
    checkLoginEnabled() {
        switch (this.LoginParameters.loginMode) {
            case this.caas.CaasKeyTypeEnum.File:
                if (this.caas.CaasIneractionMode === this.caas.CaasIneractionModeEnum.Agent) {
                    return !!this.LoginParameters.keyFilePath && !!this.LoginParameters.keyPassword;
                } else {
                    return !!this.LoginParameters.keyFile && !!this.LoginParameters.keyPassword;
                }
            case this.caas.CaasKeyTypeEnum.PKCS11ActiveMode:
            case this.caas.CaasKeyTypeEnum.PKCS11PassiveMode:
                return !!this.LoginParameters.selectedToken && !!this.LoginParameters.keyPassword;
            default:
                return false;
        }
    }

    /**
     * @public
     * @returns {Promise<void>}
     */
    async showKeyContainerChooser () {
        const { filePath } = await this.caas.showKeyContainerChooser();
        this.ee.emit(EVENTS.SELECTED_FILEPATH, filePath);
        this.LoginParameters.keyFilePath = filePath;
    }

    async prepareLoginData() {
        var res = null;
        switch (this.LoginParameters.loginMode) {
            case this.caas.CaasKeyTypeEnum.File:
                if (this.caas.CaasIneractionMode === this.caas.CaasIneractionModeEnum.Agent) {
                    res = await this.caas.signDataForLoginWithKeyStorePath(this.LoginParameters.authority, this.LoginParameters.keyFilePath, this.LoginParameters.keyPassword);
                } else {
                    var keyBinaryData = await readFileAsync(this.LoginParameters.keyFile);
                    res = await this.caas.signDataForLoginWithBinaryKey(this.LoginParameters.authority, keyBinaryData, this.LoginParameters.keyPassword);
                }
                break;
            case this.caas.CaasKeyTypeEnum.PKCS11ActiveMode:
            case this.caas.CaasKeyTypeEnum.PKCS11PassiveMode:
                res = await this.caas.signDataForLoginWithKeyStorePath(this.LoginParameters.authority, this.LoginParameters.selectedToken, this.LoginParameters.keyPassword);
                break;
            default:
                break;
        }

        if (!this.caas.success(res)) {
            const msg = (!!res.message ? res.message : "") + " " + (!!res.failureCause ? res.failureCause : "");
            this.ee.emit(EVENTS.ERR_ON_SIGN, msg);
            throw new Error(msg);
        }

        return res.value;
    }


    /**
     * @param {File} file
     */
    set keyFile(file) {
        this.LoginParameters.keyFile = file;
    }

    /**
     * @param {number} val
     */
    set keyType(val) {
        this.LoginParameters.loginMode = val;

        switch (val) {
            case this.caas.CaasKeyTypeEnum.File:
                this.ee.emit(EVENTS.SELECTED_KEY_TYPE_FILE);
                break;
            case this.caas.CaasKeyTypeEnum.PKCS11ActiveMode:
            case this.caas.CaasKeyTypeEnum.PKCS11PassiveMode:
                this.getConnectedTokens(val).then((list) => this.ee.emit(EVENTS.SELECTED_KEY_TYPE_HARDWARE, list));
                break;
            default:
                throw new UndefinedKeyTypeError(val);
        }
    }

    get keyTypes() {
        return this.caas.getAvailableKeyTypes();
    }

    set authority(val) {
        this.LoginParameters.authority = val;
    }

    set keyPass(val) {
        this.LoginParameters.keyPassword = val;
    }

    /**
     * @private
     * @param caasKeyType
     * @returns {Promise<null>}
     */
    async getConnectedTokens (caasKeyType) {
        const result = await this.caas.getConnectedTokens();

        if(!this.caas.success(result)) {
            throw new Error("failed on getting connected tokens");
        }

        switch (caasKeyType) {
            case this.caas.CaasKeyTypeEnum.PKCS11ActiveMode:
                this.LoginParameters.connectedTokens = !!result.value.activeTokenPath ? result.value.activeTokenPath : null;
                break;
            case this.caas.CaasKeyTypeEnum.PKCS11PassiveMode:
                this.LoginParameters.connectedTokens = !!result.value.passiveTokenPath ? result.value.passiveTokenPath : null;
                break;

            default:
                throw new UndefinedKeyTypeError(caasKeyType);
        }

        this.LoginParameters.selectedToken = !!this.LoginParameters.connectedTokens ? (this.LoginParameters.connectedTokens.length > 0 ? this.LoginParameters.connectedTokens[0] : "") : "";

        return this.LoginParameters.selectedToken;
    }

    async getAuthorities () {
        const { value } = await this.caas.getSupportedAuthorities();

        if(!value) {
            console.warn("no authorities are supported");
            return [];
        }

        this.LoginParameters.authority = Defaults.authority;

        return value;
    }
}
