import React from "react";
import { ConfigContext } from "./ConfigContext";
import { IConfig } from "../../config";
import BlockScreen from "components/BlockScreen";

type Props = {
    config: IConfig
}

export const ConfigProvider: React.FC<Props> = ({ children, config }) => {
    if(!config) {
        return <BlockScreen open />
    }

    return (
        <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
    );
}
