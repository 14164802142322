import React from "react";
import { useConfig } from "./hooks";
import { IConfig } from "config";

export const withConfig = <P extends WithConfigProps>(Component: React.ComponentType<P>): React.ComponentType<any> => (props) => {
    const config = useConfig();

    return <Component {...props} config={config} />
}

export type WithConfigProps = {
    config: Partial<IConfig>;
}