import React from 'react';
import setComponentsId from 'helpers/setComponentsId';
import {translate} from 'react-translate';
import Layout from 'layouts/fullPage';

const fields = [
    { id: 1, TAG: 'p' }, { id: 2, TAG: 'p' }, { id: 3, TAG: 'p' }, { id: 4, TAG: 'p' },
    { id: 5, TAG: 'p', children: [{ id: 6, TAG: 'span' }, { id: 7, TAG: 'a', href: '/terms' }, { id: 8, TAG: 'span' }] },
    { id: 9, TAG: 'h3' }, { id: 10, TAG: 'p' }, { id: 11, TAG: 'p' },
    {
        id: 12,
        TAG: 'ul',
        children: [
            { id: 13, TAG: 'li', children: [{ id: 14, TAG: 'strong' }, { id: 15, TAG: 'span' }] },
            { id: 16, TAG: 'li', children: [{ id: 17, TAG: 'strong' }, { id: 18, TAG: 'span' }] },
            { id: 19, TAG: 'li', children: [{ id: 20, TAG: 'strong' }, { id: 21, TAG: 'span' }] }
        ]
    },
    { id: 22, TAG: 'h2' }, { id: 23, TAG: 'p' }, { id: 24, TAG: 'p' },
    { id: 25, TAG: 'ul', children: [{ id: 26, TAG: 'li' }, { id: 27, TAG: 'li' }, { id: 28, TAG: 'li' }] },
    { id: 29, TAG: 'p' },
    { id: 30, TAG: 'ul', children: [{ id: 31, TAG: 'li' }, { id: 32, TAG: 'li' }, { id: 33, TAG: 'li' }] },
    { id: 34, TAG: 'h2' }, { id: 35, TAG: 'p' }, { id: 36, TAG: 'p' },
    {
        id: 37,
        TAG: 'ul',
        children: [
            { id: 38, TAG: 'li' },
            { id: 39, TAG: 'li' },
            { id: 40, TAG: 'li' },
            { id: 41, TAG: 'li' },
            { id: 42, TAG: 'li' }
        ]
    },
    { id: 43, TAG: 'p' }, { id: 44, TAG: 'h2' }, { id: 45, TAG: 'p' }, { id: 46, TAG: 'p' },
    { id: 47, TAG: 'h2' }, { id: 48, TAG: 'p' }, { id: 49, TAG: 'h2' }, { id: 50, TAG: 'p' }, { id: 51, TAG: 'h2' },
    { id: 52, TAG: 'p', children: [{ id: 53, TAG: 'span' }, { id: 54, TAG: 'a', href: 'mailto:support@kitsoft.kiev.ua' }, { id: 55, TAG: 'span' }] }
];


const renderFields = (setId: SetIdFunc, t: TFunction, child?: React.ReactNode, { id, TAG, children, href }:
    {id?: number, TAG?: string, children?: JSX.Element[], href?: string} = {}) => {
    const attributes = Object.assign(
        {
            id: setId(`${TAG}-${id}`),
            key: setId(`${TAG}-${id}`)
        },
        TAG === 'a' && href && { href },
        TAG === 'p' && { style: { textAlign: 'justify' } }
    );
    return (
        <TAG {...attributes}>
            {t(`TEXT${id}`)}
            {children && children.map(child => renderFields(setId, t, child))}
        </TAG>
    );
};
interface PolicyProps extends BaseProps { }
const Policy: React.FC<PolicyProps> = ({ setId, t }) => (
    <Layout setId={setId}>
        <h3>{t('TITLE')}</h3>
        {fields.map(item => renderFields(setId, t, item))}
    </Layout>
);

Policy.defaultProps = {
    setId: setComponentsId('policy')
};

export default translate('Policy')(Policy);
