import React, {MouseEventHandler} from 'react';
import { translate } from 'react-translate';

import {
    List,
    ListItem,
    FormControl,
    FormHelperText,
    withStyles,
    Typography
} from '@material-ui/core';

import RefreshIcon from '@material-ui/icons/Refresh';
import ProgressLine from 'components/Preloader/ProgressLine';
import DeviceItem from './DeviceItem';

const styles = {
    updateButton: {
        display: 'flex',
        alignItems: 'center'
    },
    noItems: {
        margin: 23,
        marginTop: 10,
        marginBottom: 10
    },
    grow: {
        flexGrow: 1
    }
};

interface DeviceSelectProps extends BaseProps{
    error: Error;
    kmType: string | number;
    kmDevice: KMDevice;
    kmTypes: KMType[];
    updating: boolean;
    onUpdate: MouseEventHandler;
    onChange: HardwareKeyOnChange;
}

const DeviceSelect: React.FC<DeviceSelectProps> = ({
    t,
    classes,
    error,
    kmType,
    kmTypes,
    updating,
    onUpdate,
    onChange
}) => (
    <>
        <FormControl error={!!error}>
            <List
                // @ts-ignore
                component="div" role="list"
            >
                {kmTypes.map(type => (
                    <DeviceItem
                        key={type.index}
                        type={type}
                        selected={type.index === kmType}
                        onChange={onChange}
                    />
                ))}
                {!updating && !kmTypes.length && (
                    <Typography className={classes.noItems}>
                        {t('NoItems')}
                    </Typography>
                )}
                {!updating && (
                    <ListItem
                        button
                        divider
                        onClick={onUpdate}
                        role="listitem"
                        className={classes.updateButton}
                    >
                        <div className={classes.grow} />
                        <RefreshIcon />
                        <Typography>{t('Refresh')}</Typography>
                        <div className={classes.grow} />
                    </ListItem>
                )}
            </List>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
        <ProgressLine loading={updating} />
    </>
);

const styled = withStyles(styles)(DeviceSelect)
export default translate('SignForm')(styled);