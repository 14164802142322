import React, {KeyboardEventHandler, MouseEventHandler} from 'react';
import {translate} from 'react-translate';
import {timeout, TimeoutError} from 'promise-timeout';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Hidden,
    IconButton,
    InputAdornment,
    LinearProgress,
    MenuItem,
    TextField,
    withStyles
} from '@material-ui/core';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import FileInputField from 'components/CustomInput/FileInputField';
import {readAsUint8Array} from 'helpers/readFileList';

import edsService from 'services/eds';
import Eds from 'services/eds';
import StaticLinks from './StaticLinks';
import ProxySettings from './ProxySettings';
import {createStyles} from "@material-ui/core/index";
import Signer from "../../services/eds/signer";
import HardwareSigner from "../../services/eds/hardwareSigner";
import {withConfig, WithConfigProps} from "../Config";
import {KEY_TYPES} from "../../consts";
import FileSigner from "../../services/eds/signer";
import Preloader from "../Preloader";

interface FileKeySignFormProps extends BaseProps, WithConfigProps {
    onSelectKey: (signer?: Signer | HardwareSigner, resetPrivateKey?: () => Promise<any>) => Promise<any>
    onClose?: MouseEventHandler;
}

interface FileKeyErrors {
    key?: string,
    server?: string,
    name?: string,
    password?: string
}

interface FileKeySignFormState {
    server?: number,
    password?: string,
    key?: File,
    keys?: Record<string, CertificateInfo>,
    selectedKey?: string,
    busy?: boolean;
    errors: FileKeyErrors;
    signingError?: Error
    showPassword?: boolean;
    showServerSelect?: boolean;
    showErrorDialog?: boolean;
    init: boolean;
}

const styles = createStyles({
    content: {
        padding: '0 !important',
        marginBottom: 40
    },
    grow: {
        flexGrow: 1
    },
    menuItem: {
        '@media screen and (max-width: 767px)': {
            display: 'block',
            fontSize: '12px',
            overflow: 'hidden',
            whiteSpace: 'unset',
            minHeight: 'unset',
            textOverflow: 'ellipsis'
        }
    },
    actions: {
        justifyContent: 'flex-start'
    }
});

const serviceMessages = [
    'Виникла помилка при доступі до носія ключової інформації',
    'Виникла помилка при відкритті особистого ключа (невірний пароль чи ключ пошкоджений)',
    'Сертифікат не чинний за строком дії або закінчився строк дії відповідного особистого ключа'
];

class FileKeySignForm extends React.Component<FileKeySignFormProps, FileKeySignFormState> {
    input: string = null;
    private signer: FileSigner;

    state: FileKeySignFormState = {
        server: 0,
        password: null,
        key: null,
        keys: {},
        selectedKey: null,
        busy: false,
        errors: {},
        signingError: null,
        showPassword: false,
        showServerSelect: false,
        showErrorDialog: false,
        init: false,
    };

    componentDidMount() {
        Eds.init(KEY_TYPES.FILE).then(signer => {
            this.signer = signer;

            this.setState(state => ({ ...state, init: true }));
        });
    }

    handleChange = (name: string) => ({target}: { target: { value: string } }) => {
        const {errors} = this.state;
        delete errors[name as keyof FileKeySignFormState["errors"]];

        this.setState(state => ({...state, [name]: target.value, errors}));
    };

    handleKeyChange = (key: File) => {
        if (!key) {
            return;
        }

        const {errors} = this.state;
        delete errors.key;
        this.setState(state => ({...state, key, errors}));
        this.enumKeys(key);
    }

    enumKeys = async (key: File) => {
        const keyAsUint8Array = await readAsUint8Array(key);

        const keys: FileKeySignFormState['keys'] = {};

        const enumFunc = async (index: number) => {
            try {
                const existsKey = await this.signer.execute('EnumJKSPrivateKeys', keyAsUint8Array, index) as string;
                if (existsKey) {
                    const privateKey = await this.signer.execute('GetJKSPrivateKey', keyAsUint8Array, existsKey) as JKSPrivateKey;

                    for (let c = 0; c < privateKey.certificates.length; c++) {
                        try {
                            // eslint-disable-next-line no-await-in-loop
                            await this.signer.execute('SaveCertificate', privateKey.certificates[c]);
                        } catch (e) {
                            // console.log('SaveCertificate', e);
                            // nothign to do
                        }
                    }

                    const certificates = await Promise.all(
                        privateKey.certificates.map((cert) => this.signer.execute('ParseCertificate', cert))
                    ) as CertificateInfo[];

                    const certificate = certificates.find(({keyUsage, subjDRFOCode}) => {
                        const keyUsageCheck = keyUsage === 'Протоколи розподілу ключів';
                        return this.props.config.eds.allowStamps
                            ? keyUsageCheck
                            : subjDRFOCode && keyUsageCheck;
                    });

                    if (certificate) {
                        keys[existsKey] = certificate;
                    }
                    await enumFunc(index + 1);
                }
            } catch (e) {
                // console.log('error', e);
                // Nothing to do
            }
        };

        await enumFunc(0);
        this.setState(state => ({...state, keys, selectedKey: Object.keys(keys)[0]}));
    }

    tryToSubmit: KeyboardEventHandler = ({key}) => key === 'Enter' && this.handleSelectKey();

    handleClose = () => this.setState(state => ({...state, showErrorDialog: false,}));

    async getSignCertificateWithoutCMP(key: Uint8Array, password: string): Promise<OwnerInfo> {
        const {selectedKey} = this.state;

        if (!selectedKey) {
            return null;
        }

        await this.signer.execute('SetUseCMP', false);

        const privatKeyContainer = await this.signer.execute('GetJKSPrivateKey', key, selectedKey) as JKSPrivateKey;
        const keyAsUint8Array = privatKeyContainer.privateKey;

        return this.signer.execute<OwnerInfo>('ReadPrivateKey', keyAsUint8Array, password);
    };

    getSignCertificate = async (key: Uint8Array, password: string) => {
        const {selectedKey} = this.state;

        if (!selectedKey) {
            const privateKey = await this.signer.execute('ReadPrivateKey', key, password) as OwnerInfo;
            return privateKey;
        }

        const privatKeyContainer = await this.signer.execute('GetJKSPrivateKey', key, selectedKey) as JKSPrivateKey;

        const privateKey = await this.signer.execute('ReadPrivateKey', privatKeyContainer.privateKey, password) as OwnerInfo;
        return privateKey;
    };

    readKeyOnServer = async (keyAsUint8Array: Uint8Array, password: string, serverIndex: number, iterate = true): Promise<OwnerInfo> => {
        const acskServer = edsService.CA[serverIndex];
        if (!acskServer) {
            return null;
        }

        try {
            await this.signer.execute('setServer', acskServer);
            const privateKey = await timeout(this.getSignCertificate(keyAsUint8Array, password), 10000);
            return privateKey;
        } catch (e) {
            if (e instanceof TimeoutError) {
                return null;
            }
            if (!iterate || serviceMessages.includes(e.message)) {
                throw e;
            }
            return this.readKeyOnServer(keyAsUint8Array, password, serverIndex + 1, iterate);
        }
    };

    readPrivateKey = async () => {
        const {server, key, password} = this.state;
        const keyAsUint8Array = await readAsUint8Array(key);

        let privateKey = await this.readKeyOnServer(keyAsUint8Array, password, server && (server - 1), !server);

        if (!privateKey) {
            privateKey = await this.getSignCertificateWithoutCMP(keyAsUint8Array, password);
        }

        return privateKey;
    };

    handleSelectKey = async () => {
        const {t, onSelectKey} = this.props;
        if (!onSelectKey) {
            return;
        }
        const errors = this.validate();

        if (Object.keys(errors).length) {
            this.setState(state => ({...state, errors}));
            return;
        }

        this.setState(state => ({...state, busy: true,}));

        try {
            const privateKey = await this.readPrivateKey();

            if (privateKey === null) {
                this.setState(state => ({
                    ...state,
                    busy: false,
                    errors: {
                        server: t('CantDetectACSK')
                    },
                    showServerSelect: true
                }));
                return;
            }

            await onSelectKey(this.signer, () => this.signer.execute('ResetPrivateKey'));
        } catch (e) {
            console.log('e', e);
            this.setState(state => ({
                ...state,
                signingError: (e instanceof TimeoutError) ? t(e.message) : e.message,
                showErrorDialog: true,
            }));
        }

        this.setState(state => ({...state, busy: false}));
    };

    validate() {
        const {t} = this.props;
        const {server, key, password} = this.state;
        const errors: FileKeyErrors = {};
        if (server === null) {
            errors.server = t('SelectServer');
        }

        if (!key) {
            errors.key = t('SelectKey');
        }

        if (!password) {
            errors.password = t('FillPassword');
        }

        return errors;
    }

    render() {
        const {t, classes, setId, onClose, config} = this.props;
        const {
            server,
            key,
            password,
            busy,
            errors,
            signingError,
            showErrorDialog,
            keys,
            selectedKey,
            showServerSelect,
            showPassword,
        } = this.state;

        if(!this.signer) {
            return <Preloader />
        }

        return (
            <>
                <DialogContent className={classes.content}>
                    <FormControl
                        fullWidth={true}
                        id={setId('form')}
                    >
                        <FileInputField
                            id={setId('file')}
                            label={t('Key')}
                            error={!!errors.key}
                            value={key}
                            margin="normal"
                            disabled={busy}
                            helperText={errors.key}
                            accept={this.props.config.isMobile ? null : config.IIT.FILEKEY_EXTENSIONS}
                            onChange={this.handleKeyChange}
                        />
                        {(errors.server || showServerSelect) && (
                            <TextField
                                id={setId('server')}
                                select={true}
                                label={t('ACSKServer')}
                                value={server || 0}
                                error={!!errors.server}
                                onChange={this.handleChange('server')}
                                margin="normal"
                                disabled={busy}
                                helperText={errors.server}
                                SelectProps={{MenuProps: {className: classes.menu}}}
                            >
                                <MenuItem
                                    value={0}
                                    id={setId(`server-autodetect`)}
                                    className={classes.menuItem}
                                >
                                    {t('ACSKAutoDetect')}
                                </MenuItem>
                                {edsService.CA.map((option, index) => {
                                    const name = option.issuerCNs[0];
                                    return (
                                        <MenuItem
                                            key={index}
                                            value={index + 1}
                                            id={setId(`server-${name}`)}
                                            className={classes.menuItem}
                                        >
                                            {name}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        )}
                        {Object.keys(keys).length > 1 && (
                            <TextField
                                id={setId('key')}
                                select={true}
                                label={t('SelectedKey')}
                                //@ts-ignore
                                value={selectedKey}
                                onChange={this.handleChange('selectedKey')}
                                margin="normal"
                                disabled={busy}
                                SelectProps={{MenuProps: {className: classes.menu}}}
                            >
                                {Object.keys(keys).map(option => (
                                    <MenuItem
                                        key={option}
                                        value={option}
                                        id={setId(`server-${option}`)}
                                        className={classes.menuItem}
                                    >
                                        {t(option)}
                                        {keys[option] ? ` (${keys[option].subjCN})` : null}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                        <TextField
                            id={setId('password')}
                            label={t('Password')}
                            value={password || ''}
                            error={!!errors.password}
                            onKeyPress={this.tryToSubmit}
                            onChange={this.handleChange('password')}
                            margin="normal"
                            type={showPassword ? 'text' : 'password'}
                            disabled={busy}
                            helperText={errors.password}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => this.setState(({
                                                                              showPassword,
                                                                              ...state
                                                                          }) => ({showPassword: !showPassword, ...state}))}
                                        >
                                            {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </FormControl>
                    {config.eds.useProxySettings && <ProxySettings signer={this.signer} busy={busy}/>}
                </DialogContent>
                {busy ? <LinearProgress/> : null}
                <DialogActions className={classes.actions}>
                    {onClose && (
                        <Hidden smDown={true} implementation="css">
                            <Button
                                size="large"
                                onClick={onClose}
                                disabled={busy}
                                id={setId('cancel-button')}
                            >
                                {t('Cancel')}
                            </Button>
                        </Hidden>
                    )}
                    <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={this.handleSelectKey}
                        disabled={busy}
                        id={setId('sign-button')}
                    >
                        {t('Sign')}
                    </Button>
                    <div className={classes.grow}/>
                    <StaticLinks/>
                </DialogActions>
                <Dialog
                    open={showErrorDialog}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    id={setId('dialog')}
                    className={classes.dialog}
                >
                    <DialogTitle
                        id={setId('dialog alert-dialog-title')}
                        className={classes.dialogContentWrappers}
                    >
                        {t('SigningDataError')}
                    </DialogTitle>
                    <DialogContent className={classes.dialogContentWrappers}>
                        <DialogContentText id={setId('dialog alert-dialog-description')}>
                            {signingError}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.dialogContentWrappers}>
                        <Button
                            color="primary"
                            onClick={this.handleClose}
                            autoFocus
                            id={setId('close-button')}
                        >
                            {t('CloseDialog')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

const styled = withStyles(styles)(FileKeySignForm);
const configurable = withConfig(styled as any);
export default translate('SignForm')(configurable);