import promiseChain from 'helpers/promiseChain';
import {CA, IActionRunner, Initializable, IProxySettingsConsumer, ISignerConfig, ISignerProxyConfig} from "./common";
import {KEY_TYPES} from "../../consts";
import {EdsException} from "./EdsException";

const CHARSET = 'UTF-8';
const CA_DEFAULT_SERVER = 'acskidd.gov.ua';
const CA_DEFAULT_PORT = '80';

const stringToArray = bufferString => new TextEncoder().encode(bufferString);
const arrayToString = array => new TextDecoder('utf-8').decode(array);

/**
 * Service for working with electron digital signature (EDS)
 */
class HardwareSigner implements IProxySettingsConsumer, IActionRunner, Initializable {
    error?: Error;
    signer: IEDSSigner;
    proxySettings: ISignerProxyConfig;

    actions = {
        setServer: (server, resolve, reject) => this.setServer(server).then(resolve).catch(reject),
        SetUseCMP: (useCMP, resolve, reject) => {
            const cmpSettings = this.signer.CreateCMPSettings();
            cmpSettings.SetUseCMP(useCMP);
            this.signer.SetCMPSettings(cmpSettings, resolve, reject);
        },
        ReadPrivateKey: (key, password, resolve, reject) => {
            this.signer.ReadPrivateKeyBinary(key, password, () => {
                this.signer.GetPrivateKeyOwnerInfo(resolve, reject);
            }, reject);
        },
        ReadHardwareKey: (type, device, password, resolve, reject) => {
            this.signer.ReadPrivateKeySilently(parseInt(type, 10), parseInt(device, 10), password, () => {
                this.signer.GetPrivateKeyOwnerInfo(resolve, reject);
            }, reject);
        },
        SignData: (data, internal = true, resolve, reject) => {
            if (internal) {
                this.signer.SignInternal(true, data, resolve, reject);
            } else {
                this.signer.Sign(data, resolve, reject);
            }
        },
        GetSigns: async (signedData, resolve) => {
            const list = [];
            let source = null;

            const checkSignData = async (data) => {
                const base64data = this.signer.BASE64Encode(data);
                try {
                    const signsCount = await this.execute('GetSignsCount', base64data);

                    for (let index = 0; index < signsCount; index++) {
                        const signInfo: { data?: string } = await this.execute('VerifySpecificInternal', base64data, index);
                        list.push(signInfo);
                        await checkSignData(signInfo.data);
                    }
                } catch (e) {
                    source = data || source;
                }
            };

            await checkSignData(signedData);
            resolve({source, list});
        },
        HashData: (data, resolve, reject) => this.signer.Hash(data, resolve, reject),
        VerifyDataInternal: (data, showSignerInfo = true, resolve, reject) => {
            this.signer.VerifyInternal(data, showSignerInfo, resolve, reject);
        },
        UnprotectDataByPassword: (data, password, resolve, reject) => {
            this.signer.UnprotectDataByPassword(data, password, unprotected => resolve(arrayToString(unprotected), unprotected), reject);
        },
        ProtectDataByPassword: (data, password, resolve, reject) => {
            this.signer.ProtectDataByPassword(stringToArray(data), password, resolve, reject);
        },
        ParseCertificate: (cert, resolve, reject) => {
            if (typeof cert === 'string') {
                cert = stringToArray(cert);
            }
            this.signer.ParseCertificate(cert, resolve, reject);
        },
        SaveCertificate: (cert, resolve, reject) => {
            this.signer.SaveCertificate(stringToArray(cert), resolve, reject);
        },
        ParseCertificateEx: (cert, resolve, reject) => {
            this.signer.ParseCertificateEx(stringToArray(cert), resolve, reject);
        },
        DevelopData: (data, resolve, reject) => {
            this.signer.Develop(data, false, resolve, reject);
        },
        EnvelopDataEx: (issuers, serials, isAddSign, data, asBase64String, resolve, reject) => {
            this.signer.EnvelopEx(issuers, serials, isAddSign, data, resolve, reject);
        },
        GetCertificatesByKeyInfo: (keyInfo, servers, resolve, reject) => {
            this.signer.GetCertificatesByKeyInfo(keyInfo, servers, null, resolve, reject);
        },
        SetProxySettings: (settings, resolve, reject) => {
            const proxySettings = this.signer.CreateProxySettings();

            Object.keys(settings).forEach((key) => {
                proxySettings[key] = settings[key];
            });

            this.signer.SetProxySettings(proxySettings, resolve, reject);
        },
        Base64Encode: (data, resolve, reject) => {
            this.signer.BASE64Encode(data, resolve, reject);
        }
    };

    constructor(private config: ISignerConfig, private list: Readonly<CA[]>) {
        this.proxySettings = config.proxySettings;
    }

    execute<T = any>(cmd, ...commandData): Promise<T> {
        let commandAction = this.signer[cmd] && this.signer[cmd].bind(this.signer);

        if ((cmd in this.actions)) {
            commandAction = this.actions[cmd];
        }

        if (!commandAction) {
            return Promise.reject(new Error('No method ' + cmd));
        }

        return new Promise((resolve, reject) => {
            commandData.push(resolve);
            commandData.push(error => {
                reject(new EdsException(error.message, {cmd}, KEY_TYPES.HARDWARE));
            });
            commandAction(...(commandData || []));
        });
    }

    /**
     * Method for initializing EUSignCP module
     */
    init = (): Promise<void> => new Promise((resolve, reject) => {
        const {EndUserLibraryLoader} = window;

        const libType = EndUserLibraryLoader.LIBRARY_TYPE_DEFAULT;
        const langCode = EndUserLibraryLoader.EU_DEFAULT_LANG;
        const loader = new EndUserLibraryLoader(libType, 'euSign', langCode, true);

        loader.onload = (library) => {
            this.signer = library;
            this.signer.Initialize(async () => {
               try {
                   await this.setDefaultSettings({proxySettings: this.proxySettings});
                   const certificatesResponse = await fetch('eds/CACertificates.p7b');
                   const certificateBuffer = await certificatesResponse.arrayBuffer();
                   const certificates = new Uint8Array(certificateBuffer);
                   this.signer.SaveCertificates(certificates, resolve, reject);
               } catch (e) {
                   reject(e);
               }
            }, (error) => {
                this.error = error;
                error ? reject(error) : resolve();
            });
        };

        loader.onerror = (error) => {
            this.error = error;
            reject(error);
        };

        loader.load();
    });

    async getKMDevices(typeIndex: number): Promise<KMDevice[]> {
        const list: KMDevice[] = [];
        await new Promise((resolve) => {
            const load = (currentIndex = 0) => this.signer.EnumKeyMediaDevices(
                typeIndex,
                currentIndex,
                (device) => {
                    if (device === null || device === '') {
                        return resolve();
                    }

                    list.push({ index: currentIndex, name: device });
                    return load(currentIndex + 1);
                },
                () => load(currentIndex+ 1)
            )

            load();
        });

        return list;
    }

    async getKMTypes (): Promise<KMType[]> {
        const list: KMType[] = [];

        await new Promise(resolve => {
            const load = (index = 0) => this.signer.EnumKeyMediaTypes(
                index,
                async (type) => {
                    if (type === null || type === '') {
                        return resolve();
                    }

                    list.push({index, name: type, devices: await this.getKMDevices(index)});
                    return load(index + 1);
                },
                () => load(index + 1)
            )

            load();
        });

        return list;
    };

    /**
     * Method for setting default EUSign settings
     */
    setDefaultSettings = (settings) => {
        const {EndUserLibraryLoader} = window;
        const {signer} = this;

        const fileStoreSettings = signer.CreateFileStoreSettings();
        fileStoreSettings.SetPath('');
        fileStoreSettings.SetSaveLoadedCerts(true);

        const proxySettings = signer.CreateProxySettings();
        Object.keys(settings.proxySettings).forEach((key) => {
            proxySettings[key] = settings.proxySettings[key];
        });

        const tspSettings = signer.CreateTSPSettings();
        const ldapSettings = signer.CreateLDAPSettings();
        const ocspSettings = signer.CreateOCSPSettings();
        ocspSettings.SetUseOCSP(true);
        ocspSettings.SetBeforeStore(true);
        ocspSettings.SetAddress('');
        ocspSettings.SetPort('80');
        ocspSettings.SetUseOCSP(true);

        const cmpSettings = signer.CreateCMPSettings();
        const ocspAccessInfoModeSettings = signer.CreateOCSPAccessInfoModeSettings();
        ocspAccessInfoModeSettings.SetEnabled(true);

        const modeSettings = signer.CreateModeSettings();
        modeSettings.SetOfflineMode(false);

        return promiseChain([
            () => new Promise((resolve, reject) => signer.SetRuntimeParameter(
                signer.EU_SAVE_SETTINGS_PARAMETER,
                signer.EU_SETTINGS_ID_PROXY,
                resolve, reject
            )),
            () => new Promise((resolve, reject) => signer.IsInitialized((isInitialized) => {
                if (isInitialized) {
                    return resolve();
                }
                return signer.SetUIMode(false, () => signer.Initialize(resolve, reject), reject);
            }, reject)),
            () => new Promise((resolve, reject) => signer.SetUIMode(false, resolve, reject)),
            () => new Promise((resolve, reject) => signer.SetCharset(CHARSET, resolve, reject)),
            () => new Promise((resolve, reject) => signer.SetLanguage(EndUserLibraryLoader.EU_UA_LANG, resolve, reject)),
            () => new Promise((resolve, reject) => signer.SetFileStoreSettings(fileStoreSettings, resolve, reject)),
            () => new Promise((resolve, reject) => signer.SetProxySettings(proxySettings, resolve, reject)),
            () => new Promise((resolve, reject) => signer.SetTSPSettings(tspSettings, resolve, reject)),
            () => new Promise((resolve, reject) => signer.SetLDAPSettings(ldapSettings, resolve, reject)),
            () => new Promise((resolve, reject) => signer.SetOCSPSettings(ocspSettings, resolve, reject)),
            () => new Promise((resolve, reject) => signer.SetCMPSettings(cmpSettings, resolve, reject)),
            () => new Promise((resolve, reject) => signer.SetOCSPAccessInfoModeSettings(ocspAccessInfoModeSettings, resolve, reject)),
            ...this.list.map((ca, index) => () => new Promise((resolve, reject) => {
                const ocspAccessInfoSettings = signer.CreateOCSPAccessInfoSettings();
                ocspAccessInfoSettings.SetAddress(ca.ocspAccessPointAddress);
                ocspAccessInfoSettings.SetPort(ca.ocspAccessPointPort);

                for (let j = 0; j < ca.issuerCNs.length; j++) {
                    ocspAccessInfoSettings.SetIssuerCN(ca.issuerCNs[j]);
                    signer.SetOCSPAccessInfoSettings(ocspAccessInfoSettings, (...args) => {
                        console.debug(`[EDS.HWS]: ${index+1}/${this.list.length} - ${ca.issuerCNs[0] || ca.address}`);

                        resolve(...args);
                    }, reject);
                }
            })),
            () => new Promise((resolve, reject) => {
                signer.SetModeSettings(modeSettings, resolve, reject);
            }),
            () => new Promise((resolve, reject) => {
                return signer.ResetPrivateKey(resolve, reject);
            })
        ]);
    };

    setServer = ({
                     tspAddress,
                     tspAddressPort,
                     ocspAccessPointAddress,
                     ocspAccessPointPort,
                     cmpAddress
                 }) => promiseChain([
        () => new Promise((resolve, reject) => {
            const tspSettings = this.signer.CreateTSPSettings();
            tspSettings.SetGetStamps(true);
            if (tspAddress !== '') {
                tspSettings.SetAddress(tspAddress);
                tspSettings.SetPort(tspAddressPort);
            } else {
                tspSettings.SetAddress(CA_DEFAULT_SERVER);
                tspSettings.SetPort(CA_DEFAULT_PORT);
            }
            this.signer.SetTSPSettings(tspSettings, resolve, reject);
        }),
        () => new Promise((resolve, reject) => {
            const ocspSettings = this.signer.CreateOCSPSettings();
            ocspSettings.SetUseOCSP(true);
            ocspSettings.SetBeforeStore(true);
            ocspSettings.SetAddress(ocspAccessPointAddress);
            ocspSettings.SetPort(ocspAccessPointPort);
            this.signer.SetOCSPSettings(ocspSettings, resolve, reject);
        }),
        () => new Promise((resolve, reject) => {
            const cmpSettings = this.signer.CreateCMPSettings();
            cmpSettings.SetUseCMP(!!cmpAddress);
            cmpSettings.SetAddress(cmpAddress);
            cmpSettings.SetPort(CA_DEFAULT_PORT);
            this.signer.SetCMPSettings(cmpSettings, resolve, reject);
        })
    ]);
}

export default HardwareSigner;
