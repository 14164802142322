import React from 'react';
import { translate } from 'react-translate';

import {
    Checkbox,
    TextField,
    FormControlLabel
} from '@material-ui/core';

import FormElementsGroup from 'components/FormElementsGroup';
import {IActionRunner, IProxySettingsConsumer, ISignerProxyConfig} from "../../services/eds/common";

interface Errors{
    address: string,
    port: string
}

interface ProxySettingsProps extends BaseProps{
    busy: boolean;
    signer: IProxySettingsConsumer & IActionRunner;
    errors: Errors
}

const getValidPortFromValue = (value: string): number | undefined => {
    const port = parseInt(value, 10);
    if (port < 65536 && port >= 0) {
        return port;
    }
}

class ProxySettings extends React.Component<ProxySettingsProps, never> {
    handleChange: React.ChangeEventHandler<HTMLInputElement> = ({ target: { name, value } }) => {
        const { signer } = this.props;

        this.forceUpdate();

        const changed: ISignerProxyConfig = {
            ...signer.proxySettings,
            [name]: name === 'port' ? getValidPortFromValue(value) : value
        };

        localStorage.setItem('proxySettings', JSON.stringify(changed));

        signer.execute('SetProxySettings', changed);
    };

    handleCheck: React.ChangeEventHandler<HTMLInputElement> = ({ target: { name, checked } }) => {
        const { signer, signer: { proxySettings } } = this.props;
        (proxySettings as any)[name] = checked;
        this.forceUpdate();
        localStorage.setItem('proxySettings', JSON.stringify(proxySettings));
        signer.execute('SetProxySettings', proxySettings);
    };

    render() {
        const { t, signer: { proxySettings }, errors = {} as Errors } = this.props;
        return (
            <React.Fragment>
                <FormControlLabel
                    control={
                        <Checkbox
                            name="useProxy"
                            checked={proxySettings.useProxy}
                            onChange={this.handleCheck}
                            color="default"
                        />
                    }
                    label={t('ProxySettingsEnable')}
                />
                {proxySettings.useProxy && (
                    <FormElementsGroup label={t('ProxySettings')}>
                        <TextField
                            name="address"
                            label={t('ProxySettingsAddress')}
                            error={!!errors.address}
                            helperText={errors.address}
                            value={proxySettings.address}
                            onChange={this.handleChange}
                            margin="normal"
                        />
                        <TextField
                            name="port"
                            error={!!errors.port}
                            helperText={errors.port}
                            label={t('ProxySettingsPort')}
                            value={proxySettings.port}
                            onChange={this.handleChange}
                            type="number"
                            margin="normal"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="anonymous"
                                    checked={proxySettings.anonymous}
                                    onChange={this.handleCheck}
                                    color="default"
                                />
                            }
                            label={t('ProxySettingsAnonymous')}
                        />
                        {!proxySettings.anonymous && (
                            <React.Fragment>
                                <TextField
                                    name="user"
                                    label={t('ProxySettingsUser')}
                                    value={proxySettings.user}
                                    onChange={this.handleChange}
                                    margin="normal"
                                />
                                <TextField
                                    name="password"
                                    label={t('ProxySettingsPassword')}
                                    value={proxySettings.password}
                                    onChange={this.handleChange}
                                    type="password"
                                    margin="normal"
                                />
                            </React.Fragment>
                        )}
                    </FormElementsGroup>
                )}
            </React.Fragment>
        );
    }
}

export default translate('SignForm')(ProxySettings);
