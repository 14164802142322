import React from 'react';
import setComponentsId from 'helpers/setComponentsId';
import {translate} from 'react-translate';
import {Link} from 'react-router-dom';

import {
    Card,
    CardContent,
    withStyles
} from '@material-ui/core';

import styles from 'assets/jss';
import {WithTheme} from 'themes';

interface FullPageLayoutProps extends BaseProps, WithTheme {
    footer: React.ReactNode
}

const FullPageLayout: React.FC<FullPageLayoutProps> = ({classes, children, t, footer, setId, theme}) => (
    <Card
        className={classes.fullPageLayout}
        id={setId('')}
    >
        <CardContent
            className={classes.header}
            id={setId('content')}
        >
            <Link to="/" id={setId('link-logo')}>
                <img className={classes.logo} src={theme.headerImage} style={theme.headerImageStyle} alt={t('TITLE')}/>
            </Link>
        </CardContent>
        <CardContent
            className={classes.body}
            id={setId('content2')}
        >
            {children}
        </CardContent>
        {footer &&
            <CardContent
                className={classes.footer}
                id={setId('footer')}
            >
                {footer}
            </CardContent>
        }
    </Card>
);


FullPageLayout.defaultProps = {
    setId:    setComponentsId('full-page'),
    children: '',
    footer:   ''
};

const styled = withStyles(styles, {
    withTheme: true
})(FullPageLayout as any);
export default translate('Layout')(styled);
