import React, { Fragment, Component } from 'react';
import setComponentsId from 'helpers/setComponentsId';
import { translate } from 'react-translate';

import StringElement from 'components/CustomInput/StringElement';

import {
    withStyles,
    Grid,
    Button,
    TextField,
    SnackbarContent,
    InputAdornment
} from '@material-ui/core';

import { Done } from '@material-ui/icons';
import style from 'assets/jss';

import promiseChain from 'helpers/promiseChain';

import {
    checkPhoneExists,
    sendActivationCodeSMS,
    verifyActivationCodeSMS
} from 'actions/auth';
import {withConfig, WithConfigProps} from "../../../components/Config";

const regex = /380(\d{9})/g;

interface PhoneInputProps extends BaseProps, WithConfigProps {
    value?: string,
    error?: Error,
    name?: string,
    checked?: boolean,
    label?: string,
    onChange: any,
    onCodeChange: any
}

interface PhoneInputState {
    checked: boolean,
    showActivation: boolean,
    code: string,
    value: string,
    error: Error,
    codeError: Error
}

class PhoneInput extends Component<PhoneInputProps, PhoneInputState> {
    state: PhoneInputState = {
        checked: this.props.checked,
        showActivation: false,
        code: '',
        value: this.props.value,
        error: this.props.error,
        codeError: null
    };
    //@ts-ignore
    componentWillReceiveProps({ error }) {
        if (!this.state.error) {
            this.setState(() => ({ error }));
        }
    }

    valid = (phone: string) => phone.match(regex);

    checkPhoneExists = async () => {
        const { t } = this.props;
        const { value } = this.state;
        const { text } = await checkPhoneExists(value);
        if (text !== 'null') {
            throw t('PHONE_ALREADY_EXISTS');
        }
    };

    checkCodeValid = async () => {
        const { t } = this.props;
        const { code } = this.state;
        if (!code) {
            throw t('EMPTY_CODE_ERROR');
        }
    };

    verifyActivationCode = async () => {
        const { t } = this.props;
        const { value, code } = this.state;
        const result = await verifyActivationCodeSMS(value, code);

        if (result !== 'confirm') {
            throw t('ACTIVATION_CODE_INVALID');
        }
    };

    handleFinish = () => {
        const { onChange, onCodeChange } = this.props;
        const { value, code } = this.state;

        onChange && onChange({ target: { value } }, () => {
            code && onCodeChange && onCodeChange({ target: { value: code } });
        });
    };

    handleNotNow = () => promiseChain([
        this.checkPhoneExists,
        () => this.setState(() => ({ checked: true, error: null, showActivation: false, code: '' }), this.handleFinish)
    ]).catch(error => this.setState(() => ({ error })));

    handleToggleActivation = () => promiseChain([
        this.checkPhoneExists,
        this.handleSendCode,
        () => this.setState(() => ({ showActivation: true }))
    ]).catch(error => this.setState(() => ({ error })));

    handleChange = ({ target: { value } }: {target: {value: string}}) => {
        const { onChange } = this.props;
        this.setState(() => ({ checked: false, value, error: null }));

        if (!this.props.config.SHOW_PHONE_CONFIRM) {
            onChange && onChange({ target: { value } });
        }
    };

    handleChangeCode = ({ target: { value } }: {target: {value: string}}) => this.setState(() => ({ code: value, codeError: null }));

    handleSendCode = async () => {
        const { t } = this.props;
        const { value } = this.state;
        const result = await sendActivationCodeSMS(value);
        if (result === 'exist') {
            throw t('PHONE_ALREADY_EXISTS');
        }
    };

    handleActivate = () => promiseChain([
        this.checkCodeValid,
        this.verifyActivationCode,
        () => this.setState(() => ({ checked: true, showActivation: false }), this.handleFinish)
    ]).catch(error => this.setState(() => ({ codeError: error })));

    renderActivation() {
        const { t, classes, setId } = this.props;
        const { code, codeError } = this.state;
        return (
            <Fragment>
                <SnackbarContent
                    className={classes.successSnackbar}
                    message={t('ACTIVATIONS_BY_SMS_TEXT')}
                />
                <Grid
                    container={true}
                    spacing={8}
                    id={setId('container')}
                >
                    <Grid
                        item={true}
                        xs={4}
                        id={setId('grid')}
                    >
                        <TextField
                            name="code"
                            margin="none"
                            value={code}
                            error={!!codeError}
                            helperText={codeError}
                            label={t('ACTIVATION_CODE')}
                            onChange={this.handleChangeCode}
                            className={classes.fullWidth}
                            id={setId('code')}
                        />
                    </Grid>
                    <Grid
                        item={true}
                        xs={4}
                        id={setId('grid-2')}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.fullWidth}
                            onClick={this.handleActivate}
                            id={setId('activate-button')}
                            //@ts-ignore
                            setId={elementName => setId(`activate-button-${elementName}`)}
                        >
                            {t('ACTIVATE')}
                        </Button>
                    </Grid>
                    <Grid
                        item={true}
                        xs={4}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.fullWidth}
                            onClick={this.handleNotNow}
                            id={setId('not-now-button')}
                            //@ts-ignore
                            setId={elementName => setId(`not-now-button-${elementName}`)}
                        >
                            {t('NOT_NOW')}
                        </Button>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    renderActivationRequest() {
        const { t, classes, setId } = this.props;
        return (
            <Grid
                container={true}
                spacing={8}
                id={setId('container')}
            >
                <Grid
                    item={true}
                    xs={6}
                    id={setId('grid')}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.fullWidth}
                        onClick={this.handleToggleActivation}
                        id={setId('activate-button')}
                        //@ts-ignore
                        setId={elementName => setId(`activate-button-${elementName}`)}
                    >
                        {t('ACTIVATE_PHONE')}
                    </Button>
                </Grid>
                <Grid
                    item={true}
                    xs={6}
                    id={setId('grid-2')}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.fullWidth}
                        onClick={this.handleNotNow}
                        id={setId('not-now-button')}
                        //@ts-ignore
                        setId={elementName => setId(`not-now-button-${elementName}`)}
                    >
                        {t('NOT_NOW')}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    render() {
        const {
            classes,
            name,
            label,
            setId
        } = this.props;

        const {
            value,
            error,
            checked,
            showActivation
        } = this.state;
        const showActivationRequest = this.valid(value) && !checked && !showActivation;

        return (
            <Fragment>
                <StringElement
                    disabled={showActivation || checked}
                    error={error}
                    name={name}
                    label={label}
                    value={value || ''}
                    onChange={this.handleChange}
                    mask="380999999999"
                    setId={(elementName: string) => setId(`${name}-${elementName}`)}
                    InputProps={checked ? {
                        endAdornment: (
                            //@ts-ignore
                            <InputAdornment>
                                <Done className={classes.successIcon} />
                            </InputAdornment>
                        )
                    } : {}}
                />
                {this.props.config.SHOW_PHONE_CONFIRM && showActivationRequest && this.renderActivationRequest()}
                {this.props.config.SHOW_PHONE_CONFIRM && showActivation && this.renderActivation()}
            </Fragment>
        );
    }

    static defaultProps: Partial<PhoneInputProps> = {
        setId: setComponentsId('phone-input'),
        checked: false,
        error: null,
    };
}

const styled = withStyles(style)(PhoneInput);
const configured = withConfig(styled as any);
export default translate('RegisterForm')(configured);
