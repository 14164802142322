import React from 'react';
import { translate } from 'react-translate';
import Signer from "../../../services/eds/signer";
import {withStyles, Typography} from '@material-ui/core';

import Layout from 'layouts/topHeader';
import EDSForm from 'components/EDSForm';
import EDSCForm from 'components/EDSCForm';
import {READERS} from "../../../consts";

import GovIdButton from './GovIdButton';
import {withConfig, WithConfigProps} from "../../../components/Config";

interface DefaultLoginLayoutProps extends BaseProps, WithConfigProps {
    token?: string;
    onSelectKey: (cert?: OwnerInfo, signer?: Signer, resetPrivateKey?: () => Promise<any>) => Promise<any>
}

const styles = {
    title: {
        fontSize: '1.4rem'
    }
};

const NoReaderAvailable = () => (
    <div>No reader available...  ¯\_(ツ)_/¯ </div>
);
const readers = {
    [READERS.IIT]: EDSForm,
    [READERS.CIPHER]: EDSCForm,
    default: NoReaderAvailable
};

const DefaultLoginLayout: React.FC<DefaultLoginLayoutProps> = ({ t, classes, setId = () => '', onSelectKey = () => null, token, config }) => {
    const CurrentReader = readers[config.providers.KEY_READER] || readers.default;

    return (
        <Layout
            setId={setId}
        >
            {config.providers.GOV_ID && <GovIdButton />}
            <Typography
                variant="headline"
                gutterBottom
                id={setId('title')}
                className={classes.title}
            >
                {t('TITLE')}
            </Typography>
            <CurrentReader
                token={token}
                onSelectKey={onSelectKey}
                setId={elementName => setId(`sign-form-${elementName}`)}
            />
        </Layout>
    );
};

const styled = withStyles(styles)(DefaultLoginLayout);
const configured = withConfig(styled as any);
export default translate('LoginPage')(configured);
