import React, { Fragment, Component } from 'react';
import setComponentsId from 'helpers/setComponentsId';

import { Link } from 'react-router-dom';
import { translate } from 'react-translate';
import { connect } from 'react-redux';

import {WithTheme} from 'themes';

import Ajv from 'ajv';

import {
    withStyles,
    FormControl,
    FormLabel,
    TextField,
    FormControlLabel,
    Checkbox,
    Button,
    Typography
} from '@material-ui/core';

import { Info } from '@material-ui/icons';

import { fourteenYearsAgo, today } from 'helpers/humanDateFormat';
import normalizeErrors from 'helpers/normalizeErrors';

import CustomDatePicker from 'components/CustomInput/CustomDatePicker';

import style from 'assets/jss';

import { legalSchema, personSchema } from 'pages/Register/validateSchemas';

import PhoneInput from './PhoneInput';
import EmailInput from './EmailInput';
import {withConfig, WithConfigProps} from "../../../components/Config";

export interface Values{
    first_name?: string,
    last_name?: string,
    middle_name?: string,
    ipn?: string,
    companyName?: string,
    isLegal?: boolean,
    userIdentificationType?: string,
    agreement?: boolean;
    phone?: string,
    email?: string,
    code_email?: string,
    code_phone?: string,
    birthday?: string,
    legalEntityDateRegistration?: string;
    edrpou?: string,
    isIndividualEntrepreneur?: boolean
}

type Errors = Partial<Record<keyof Values, boolean>>;

interface RegisterFormProps extends BaseProps, WithConfigProps, WithTheme {
    errors: Errors
    values: Values,
    onSubmit: any
}
interface RegisterFormState{
    values: Values,
    errors: Errors
}

const ajv = new Ajv({ ownProperties: true, allErrors: true });

class RegisterForm extends Component<RegisterFormProps, RegisterFormState> {
    componentDidMount() {
        const {errors, values} = this.props;

        this.setState(() => ({
            errors,
            values: {
                ...(values || {}),
                agreement: this.props.theme.hideTermsLink
            }
        }));
    }

    handleChange = (name: string) => ({ target: { value } }: {target: {value: any}}, callback?:()=>void) => this.setState({
        values: {
            ...this.state.values,
            [name]: value
        },
        errors: {
            ...this.state.errors,
            [name]: null
        }
    }, callback);

    handleCheck = (name: string) => ({ target: { checked } }: { target: {checked: boolean}}) =>
        this.handleChange(name)({ target: { value: checked } });

    onDateChange = (name: string) => (date: string) =>
        this.handleChange(name)({ target: { value: date } });

    handleSubmit = () => {
        const { t, onSubmit, config: { SHOW_PHONE } } = this.props;
        const { values } = this.state;
        const { isLegal } = values;

        const validator = ajv.compile(isLegal ? legalSchema : personSchema);
        validator(values);

        const errors: any = normalizeErrors(validator.errors || [], t);

        if (!SHOW_PHONE) delete errors.phone;

        this.setState(state => ({ ...state, errors }));
        if (!Object.keys(errors).length) {
            onSubmit && onSubmit(values);
        }
    };

    handleDiscard = () => {
        window.location.href = '/logout';
    };

    renderTextField = (name: keyof Values, rest: object) => {
        const { t } = this.props;
        const { values, errors } = this.state;
        return (
            <TextField
                {...rest}
                name={name}
                error={!!(errors || {})[name]}
                helperText={(errors || {})[name] || ''}
                label={t(name.toUpperCase() + '_INPUT_LABEL')}
                value={((values || '') as any)[name] || ''}
                onChange={this.handleChange(name)}
                margin="normal"
            />
        );
    };

    renderName = () => {
        const { values: { last_name, first_name, middle_name } } = this.state;

        return (
            <FormControl fullWidth={true}>
                <Typography
                    //@ts-ignore
                    variant="h5" component="h3">
                    {(last_name || '') + ' ' + (first_name || '') + ' ' + (middle_name || '')}
                </Typography>
            </FormControl>
        );
    }

    renderPhoneField = () => {
        const { t, setId, config: { SHOW_PHONE } } = this.props;
        const { errors, values: { phone } } = this.state;

        if (!SHOW_PHONE) return null;

        return (
            <PhoneInput
                name="phone"
                error={(errors || {}).phone}
                label={t('PHONE_INPUT_LABEL')}
                value={phone || ''}
                onChange={this.handleChange('phone')}
                onCodeChange={this.handleChange('code_phone')}
                setId={(elementName: string) => setId(`phone-${elementName}`)}
            />
        );
    };

    renderEmailField = () => {
        const { t, setId } = this.props;
        const { errors, values: { email } } = this.state;
        return (
            <EmailInput
                name="email"
                error={(errors || {}).email}
                label={t('EMAIL_INPUT_LABEL')}
                value={email || ''}
                onChange={this.handleChange('email')}
                onCodeChange={this.handleChange('code_email')}
                setId={(elementName: string)=> setId(`email-${elementName}`)}
            />
        );
    };

    renderLegalDateRegistrationField = () => {
        const { t, setId } = this.props;
        const { errors, values: { legalEntityDateRegistration } } = this.state;

        return (
            <CustomDatePicker
                name="birthday"
                error={(errors || {}).legalEntityDateRegistration}
                label={t('REG_DATE_INPUT_LABEL')}
                margin="normal"
                incomingFormat="DD/MM/YYYY"
                onChange={this.onDateChange('legalEntityDateRegistration')}
                date={legalEntityDateRegistration || ''}
                minDate="01/01/1900"
                maxDate={today('DD/MM/YYYY')}
                setId={(elementName: string) => setId(`birthday-${elementName}`)}
            />
        );
    };

    renderBirthdayField = () => {
        const { t, setId } = this.props;
        const { errors, values: { birthday } } = this.state;

        return (
            <CustomDatePicker
                name="birthday"
                error={(errors || {}).birthday}
                label={t('BIRTHDAY_INPUT_LABEL')}
                margin="normal"
                incomingFormat="DD/MM/YYYY"
                onChange={this.onDateChange('birthday')}
                date={birthday || ''}
                minDate="01/01/1900"
                maxDate={fourteenYearsAgo('DD/MM/YYYY')}
                setId={(elementName: string) => setId(`birthday-${elementName}`)}
            />
        );
    };

    renderCheckboxField = (name: string) => {
        const { t } = this.props;
        const { values } = this.state;
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={!!((values || '') as any)[name]}
                        onChange={this.handleCheck(name)}
                        color="primary"
                    />
                }
                label={t(name.toUpperCase() + '_INPUT_LABEL')}
            />
        );
    };

    renderLegalForm() {
        return (
            <Fragment>
                {this.renderTextField('companyName', { disabled: true })}
                {this.props.theme.useDateRegistration && this.renderLegalDateRegistrationField()}
                {this.renderTextField('edrpou', { disabled: true })}
                {this.renderPhoneField()}
                {this.renderEmailField()}
            </Fragment>
        );
    }

    renderPersonForm() {
        const { classes } = this.props;
        return (
            <Fragment>
                {/* {this.renderTextField('last_name', { disabled: true })}
                {this.renderTextField('first_name', { disabled: true })}
                {this.renderTextField('middle_name', { disabled: true })} */}
                {this.renderName()}
                {this.props.theme.useBirthday && this.renderBirthdayField()}
                {this.renderTextField('ipn', { disabled: true, className: classes.ipn })}
                {this.props.theme.useIndividualEntrepreneur && this.renderCheckboxField('isIndividualEntrepreneur')}
                {this.renderPhoneField()}
                {this.renderEmailField()}
            </Fragment>
        );
    }

    render() {
        if(!this.state) {
            return null;
        }

        const { t, classes, setId } = this.props;
        const { values, errors } = this.state;

        const { isLegal, agreement } = values || {};

        return (
            <FormControl
                fullWidth={true}
                className={classes.formControl}
                id={setId('')}
            >
                {isLegal ?
                    this.renderLegalForm() :
                    this.renderPersonForm()
                }
                {this.props.theme.useFAQLink &&
                    <a
                        href="https://wiki.court.gov.ua/pages/viewpage.action?pageId=8126525&moved=true"
                        target="_blank"
                        id={setId('link-to-faq')}
                        className={classes.faqLink}
                        rel="noopener noreferrer"
                    >
                        <Button
                            //@ts-ignore
                            color="transparent"
                            className={classes.minButton}
                            id={setId('link-to-faq-button')}
                        >
                            <Info />
                            {t('FAQ_LINK')}
                        </Button>
                    </a>
                }
                <FormControl
                    required={true}
                    error={errors.agreement}
                    //@ts-ignore
                    component="fieldset"
                    className={classes.formControl}
                    id={setId('control-agreement')}
                >
                    {!!errors.agreement &&
                        <FormLabel id={setId('label-agreement')}>
                            {t('AGREEMENT_REQUIRED')}
                        </FormLabel>
                    }
                    {this.props.theme.hideTermsLink ? null : (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={agreement || false}
                                    onChange={this.handleCheck('agreement')}
                                    id={setId('checkbox-agreement')}
                                />
                            }
                            label={t('AGREEMENT_TEXT', {
                                link: (
                                    <Link
                                        to="/terms"
                                        target="_blank"
                                        id={setId('link-to-terms')}
                                    >
                                        {t('TERMS_LINK')}
                                    </Link>
                                )
                            })}
                        />
                    )}
                </FormControl>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.fullWidth}
                    onClick={this.handleSubmit}
                    id={setId('activate-button')}
                    //@ts-ignore
                    setId={elementName => setId(`activate-button-${elementName}`)}
                >
                    {t('ACTIVATE')}
                </Button>
                <Button
                    className={classes.fullWidth}
                    onClick={this.handleDiscard}
                    //@ts-ignore
                    color="transparent"
                    id={setId('discard-button')}
                    setId={(elementName: string) => setId(`discard-button-${elementName}`)}
                >
                    {t('DISCARD')}
                </Button>
            </FormControl>
        );
    }
    static defaultProps: Partial<RegisterFormProps> = {
        setId: setComponentsId('left-side-bar'),
        errors: {},
        values: {},
        onSubmit: undefined
    };
}



const configured = withConfig(RegisterForm as any);
const styled = withStyles(style, {
    withTheme: true
})(configured);
const translated = translate('RegisterForm')(styled);
const mapStateToProps = () => ({});
export default connect(mapStateToProps)(translated);
