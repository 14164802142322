import {EDSActions} from "./eds";
import {AuthActions} from "./auth";

export enum Actions {
    GET_AUTH_SUCCESS = 'GET_AUTH_SUCCESS',
    GET_AUTH_FAIL = 'GET_AUTH_FAIL',
    DB_ERROR = 'DB_ERROR',
    ERROR_503 = 'ERROR_503',
    
    REQUEST_EDS_SERVER_LIST_SUCCESS = 'REQUEST_EDS_SERVER_LIST_SUCCESS',
    REQUEST_SIGN_DATA_SUCCESS = 'REQUEST_SIGN_DATA_SUCCESS',
    
    SET_GOV_ID = 'SET_GOV_ID',
    GET_GOV_ID = 'GET_GOV_ID',

    EDS_ADD_KM_DEVICE = `eds/hardware/addKmDevice`,
    EDS_ADD_KM_TYPE = `eds/hardware/addKmType`,
    EDS_INITIATED = `eds/hardware/libraryInitSuccess`,
    EDS_LIBRARY_INIT_FAILED = `eds/hardware/libraryInitFailed`,
    EDS_CLEAR_TYPES = `eds/hardware/clearTypes`,
}

interface GetAuthSuccessAction {
    type: Actions.GET_AUTH_SUCCESS,
    payload: {
        info?: UserInfo,
        user?: unknown,
        provider?: Providers
    },
    url: string,
    method: string,
    body?: string,
}

interface GetAuthFailAction {
    type: Actions.GET_AUTH_FAIL,
    payload: object,
    url: string,
    method: string,
}

interface DBErrorAction {
    type: Actions.DB_ERROR,
    payload: boolean,
}

interface Error503Action {
    type: Actions.ERROR_503,
    payload: boolean,
}

interface RequestEdsServerListSuccessAction {
    type: Actions.REQUEST_EDS_SERVER_LIST_SUCCESS,
    payload: {
        list: string[]
    }
    url: string,
    method: string,
}

interface RequestSignDataSuccessAction {
    type: Actions.REQUEST_SIGN_DATA_SUCCESS,
    payload: {token?: string},
    url: string,
    method: string,
}

interface EdsClearTypesAction {
    type: Actions.EDS_CLEAR_TYPES,
}

interface EdsAddKmTypeAction {
    type: Actions.EDS_ADD_KM_TYPE,
    payload: {type: string, index: number}
}

interface EdsAddKmDeviceAction {
    type: Actions.EDS_ADD_KM_DEVICE,
    payload: {
        device: string,
        typeIndex: number,
        deviceIndex: number,
    }
}

interface EdsLibraryInitFailedAction {
    type: Actions.EDS_LIBRARY_INIT_FAILED,
    payload: unknown,
}

interface EdsInitedAction {
    type: Actions.EDS_INITIATED,
}

export type AuthActionTypes = GetAuthSuccessAction | GetAuthFailAction | DBErrorAction | Error503Action;
export type EdsActionTypes =
    RequestEdsServerListSuccessAction
    | RequestSignDataSuccessAction
    | EdsClearTypesAction
    | EdsAddKmTypeAction
    | EdsAddKmDeviceAction
    | EdsLibraryInitFailedAction
    | EdsInitedAction
export type ActionTypes = AuthActions | EDSActions
    | Actions.SET_GOV_ID
    | Actions.GET_GOV_ID
    | string
