import React from "react";
import PropTypes from "prop-types";
import Signer, { EVENT_TYPES, DEFAULT_AGENT_LINK } from "services/edsc";
// import ky from "ky-universal";
import { Button, Select, MenuItem, TextField, Grid, Card } from "@material-ui/core";
import { READERS, KEY_TYPES } from "../../consts";
import config from "../../config";
import { get } from "lodash";

export default class CipherForm extends React.Component {

    static propTypes = {
        onSelectKey: PropTypes.func.isRequired,
        token: PropTypes.string.isRequired,
    };

    state = {
        err: null,
        isProgress: false,
        enabled: false,
        keyTypes: [],
        authorities: [],
        token: null,
        vKeyType: null,
        vAuthority: null,
        vContainer: null,
        vPass: null
    };

    componentDidMount() {
        this.fileInput = React.createRef();

        this.signer = new Signer();
        this.signer.ee.addListener(EVENT_TYPES.SET_DISABLED, () => this.setState({ enabled: false }));
        this.signer.ee.addListener(EVENT_TYPES.SET_ACTIVE, () => {
            this.setState({ enabled: true }, () => {
                this.signer.getAuthorities().then((list) => {
                    const authorities = Object.entries(list);
                    this.setState({ authorities }, () => {
                        this.handleSelectAuthority({
                            target: {
                                value: get(authorities, '0.0')
                            }
                        })
                    });
                })

                const keyTypes = Object.entries(this.signer.keyTypes);
                this.setState({
                    keyTypes: keyTypes.filter(
                        ([id]) => !config.providers?.KEY_TYPE ? true : config.providers?.KEY_TYPE === KEY_TYPES.FILE && id === '0'
                            || config.providers?.KEY_TYPE === KEY_TYPES.HARDWARE && ['1', '2'].includes(id))
                }, () => {
                    this.handleSelectKeyType({
                        target: {
                            value: keyTypes[0][0]
                        }
                    })
                });
            });
        });
        this.signer.ee.addListener(EVENT_TYPES.SELECTED_KEY_TYPE_HARDWARE, token => {
            this.setState({ token });
        });
        this.signer.ee.addListener(EVENT_TYPES.SELECTED_FILEPATH, path => {
            this.setState({ vContainer: path });
        });

        // this.signer.init(ky);
    }

    componentWillUnmount() {
        this.signer.ee.removeAllListeners();
        this.signer = null;
        this.setState({ enabled: false })
    }

    handleLoadAgent = () => {
        window.location.href = DEFAULT_AGENT_LINK;
    };

    handleSelectAuthority = (e) => {
        this.signer.authority = e.target.value;
        this.setState({ vAuthority: e.target.value });
    };

    handleSelectKeyType = (e) => {
        this.signer.keyType = +e.target.value;
        this.setState({ vKeyType: e.target.value });
    };

    handleStartSelectFile = (e) => {
        this.signer.showKeyContainerChooser();
    };

    handleFinishSelectFile = (e) => {
        const file = e.target.files[0];
        this.signer.keyFile = e.target.files[0] || null;
        this.setState({ vContainer: file.name })
    };

    handleChangePassword = (e) => {
        this.signer.keyPass = e.target.value;
        this.setState({ vPass: e.target.value });
    };

    handleProgress = async (cb = () => {}) => new Promise((resolve, reject) => {
        this.setState({
            inProgress: true,
            err: null
        }, () => {
            cb()
                .then(() => {
                    this.setState({
                        inProgress: false
                    }, () => resolve());
                })
                .catch((e) => {
                    this.setState({
                        err: e.message,
                        inProgress: false
                    }, () => reject());
                })
        })
    });

    handleSelectKey = () => this.handleProgress(() =>
        this.signer
            .prepareLoginData()
            .then(signature => this.props.onSelectKey({
                type: READERS.CIPHER,
                signature,
                token: this.props.token
            }))
    );

    render(){
        const { enabled, authorities, keyTypes, vAuthority, vKeyType, vContainer, vPass, token, err, inProgress } = this.state;

        if(!enabled) {
            return (
                <div className="container">
                    <div class="tip">
                        Для входу перевірте наявність наступних компонентів:

                        <ul>
                            <li>
                                <a href="#" onClick={this.handleLoadAgent}>агент підпису</a>
                            </li>
                            <li>
                                <a href="https://java.com/ru/download/">
                                    середовища для виконання java додатків
                                </a>
                            </li>
                        </ul>

                        Також Ви маєте бути певні, що маєте право доступу до кабінету.
                    </div>
                </div>
            );
        }

        return (
            <div className="container" style={{ paddingRight: 30 }}>
                {err && (
                    <Card style={{
                        padding: 10,
                        backgroundColor: "#ff000014",
                        width: "100%",
                        marginBottom: 40
                    }}>{err}</Card>
                )}

                <div className="row">
                    <label htmlFor="authorities">КНЕДП/АЦСК:</label>
                    <Select className="form-control" onChange={this.handleSelectAuthority} value={vAuthority} disabled={inProgress}>
                        {authorities.map(([value, name]) => (
                            <MenuItem value={value}>{name}</MenuItem>
                        ))}
                    </Select>
                </div>

                <div className="row">
                    <label htmlFor="keyType" className="col-form-label">Тип ключа</label>
                    <Select id="keyType" className="form-control" onChange={this.handleSelectKeyType} value={vKeyType} disabled={inProgress}>
                        {keyTypes.map(([value, name]) => (
                            <MenuItem value={value}>{name}</MenuItem>
                        ))}
                    </Select>
                </div>

                <div className="row">
                    <label className="col-form-label">Шлях до контейнеру:</label>
                    <Grid container>
                        <Grid item xs={9} style={{
                            justifyContent: "center",
                            alignContent: "center",
                            justifyItems: "center",
                            alignItems: "center",
                            display: "flex"
                        }}>{this.signer.isHardwareMode ? token : vContainer}</Grid>
                        <Grid item xs={3}>
                            <Button className="input-group-text" onClick={this.signer.isFileMode && this.handleStartSelectFile} disabled={inProgress}>
                                Вибрати {this.signer.isHardwareMode ? "токен" : "файл"}
                            </Button>
                        </Grid>
                    </Grid>
                </div>

                {(vContainer || token) && (
                    <div className="row">
                        <label htmlFor="keyPassword">Пароль:</label>
                        <TextField id="keyPassword" type="password" className="form-control" disabled={inProgress}
                                   onChange={this.handleChangePassword} value={vPass} />
                    </div>
                )}

                <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    onClick={this.handleSelectKey}
                    disabled={!vPass || inProgress}
                >
                    Продовжити
                </Button>
            </div>
        );
    }
}
