import React from 'react';
import {Button, withStyles} from "@material-ui/core";
import {translate} from "react-translate";
import style from '../../assets/jss';

import Terms from "../../containers/Terms";

interface AgreementProps extends BaseProps { onContinue: () => void }

const Agreement: React.FC<AgreementProps> = ({t, classes, setId, onContinue}) => {
    return (
        <Terms>
            <Button
                className={classes.fullWidth}
                variant="contained"
                color="primary"
                id={setId('continue-button')}
                onClick={onContinue}
            >
                {t('CONTINUE')}
            </Button>
        </Terms>
    );
}

const styled = withStyles(style)(Agreement);
const translated = translate('AgreementPage')(styled);

export default translated
