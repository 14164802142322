import {Actions, EdsActionTypes} from "../actions/types";
import {Reducer} from "redux";

interface State {
    kmTypes: KMType[];
    inited: boolean;
    dataToSign?: string;
    serverList?: string[];
    error?: unknown;
}

const initialState: State = {
    kmTypes: [],
    inited: false,
};

export const eds: Reducer<State, EdsActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case Actions.REQUEST_EDS_SERVER_LIST_SUCCESS:
            return {...state, serverList: action.payload.list};
        case Actions.REQUEST_SIGN_DATA_SUCCESS:
            return {...state, dataToSign: action.payload.token};
        case Actions.EDS_CLEAR_TYPES:
            return {...state, kmTypes: []};
        case Actions.EDS_ADD_KM_TYPE: {
            const {type, index} = action.payload;
            state.kmTypes[index] = {name: type, index, devices: []};
            return {...state, kmTypes: state.kmTypes};
        }
        case Actions.EDS_ADD_KM_DEVICE: {
            const {device, typeIndex, deviceIndex} = action.payload;
            state.kmTypes[typeIndex].devices[deviceIndex] = {index: deviceIndex, name: device};
            return {...state, kmTypes: state.kmTypes};
        }
        case Actions.EDS_INITIATED: {
            return {...state, inited: true};
        }
        case Actions.EDS_LIBRARY_INIT_FAILED:
            return {...state, inited: true, error: action.payload};
        default:
            return state;
    }
};
