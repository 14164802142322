import {Actions, AuthActionTypes} from "../actions/types";
import {Reducer} from "redux";

interface State {
    DBError: boolean;
    ERROR_503: boolean;
    userUnits?: unknown;
    token?: string;
    info?: UserInfo,
    user?: unknown,
    provider?: Providers
}

const initialState: State = {
    DBError: false,
    ERROR_503: false
};

export const auth: Reducer<State, AuthActionTypes> = (state = initialState, action: AuthActionTypes) => {
    switch (action.type) {
        case Actions.GET_AUTH_SUCCESS:
            return {...state, ...action.payload, DBError: false};
        case Actions.GET_AUTH_FAIL:
        case Actions.DB_ERROR:
            return {...state, DBError: true};
        case Actions.ERROR_503:
            return {...state, ERROR_503: action.payload};
        default:
            return state;
    }
};
