import React from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import EmptyPage from 'components/EmptyPage';
import {RootState} from "../../reducers";

interface AuthProps extends BaseProps{
    DBError: boolean,
    ERROR_503: boolean
}

const styles = {
    wrap: {
        paddingTop: 64,
        paddingLeft: 260,
        '@media (max-width: 959px)': {
            paddingLeft: 0
        }
    }
};

const Auth: React.FC<AuthProps> = ({ classes, children, t, DBError, ERROR_503 }) => {
    if (!DBError) {
        return <>{children}</>;
    }

    return (
        <div className={classes.wrap}>
            <EmptyPage
                title={t('ERROR')}
                description={t('ERROR_DESCRIPTION')}
            />
        </div>
    );
}

const translated = translate('Auth')(Auth);
const styled = withStyles(styles)(translated);
export default connect(({ auth: { DBError, ERROR_503 } }: RootState) =>
    ({ DBError, ERROR_503 }))(styled);
