import React from 'react';
import {TimeoutError} from 'promise-timeout';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Hidden,
    IconButton,
    InputAdornment,
    LinearProgress,
    MenuItem,
    TextField,
    Typography,
    withStyles
} from '@material-ui/core';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import Preloader from 'components/Preloader';
import edsService from 'services/eds';
import Eds from 'services/eds';
//@ts-ignore
import renderHTML from 'react-render-html';


import DeviceSelect from './components/DeviceSelect';
import ProxySettings from '../ProxySettings';
import StaticLinks from '../StaticLinks';
import Signer from "../../../services/eds/signer";
import HardwareSigner from "../../../services/eds/hardwareSigner";
import {withConfig, WithConfigProps} from "../../Config";
import {KEY_TYPES} from "../../../consts";
import {translate} from "react-translate";

type DispatchActionProps = {
    addKmType: (payload: unknown) => void;
    addKMDevice: (payload: unknown) => void;
}

interface HardwareKeySignFormProps extends Required<BaseProps>, WithConfigProps, DispatchActionProps {
    kmTypes: KMType[],
    inited: boolean,
    onSelectKey: (signer?: Signer | HardwareSigner, resetPrivateKey?: () => Promise<any>) => Promise<any>,
    onClose: any
}

interface HardwareKeySignFormState {
    server: number,
    password: string | null,
    error: Error | null,
    kmType: string | number,
    kmTypes: KMType[];
    kmDevice: string,
    busy: boolean,
    updating: boolean,
    errors: Record<string, string>,
    signingError: Error | null,
    showErrorDialog: boolean,
    showServerSelect: boolean,
    showPassword: boolean,
    waiting: boolean,
    itsMobile: boolean,
    key: string;
    init: boolean;
}

const styles = {
    content: {
        padding: '0 !important',
        marginBottom: 40
    },
    grow: {
        flexGrow: 1
    },
    errorText: {
        padding: 20,
        marginBottom: 20,
        paddingLeft: 0,
        fontSize: '1rem',
        lineHeight: '1.5em',
        color: 'rgba(0, 0, 0, 0.87)',
        '& > div > a': {
            textDecoration: 'none',
            color: '#0059aa'
        },
        '& > div > a:hover': {
            textDecoration: 'underline',
            color: '#000'
        },
        '@media screen and (max-width: 767px)': {
            display: 'block',
            fontSize: 14
        }
    },
    actions: {
        justifyContent: 'flex-start'
    }
};

const serviceMessages = [
    'Виникла помилка при доступі до носія ключової інформації',
    'Виникла помилка при зчитуванні особистого ключа з носія ключової інформації'
];

class HardwareKeySignForm extends React.Component<HardwareKeySignFormProps, HardwareKeySignFormState> {
    state: HardwareKeySignFormState = {
        server: 0,
        password: null,
        error: null,
        kmType: '',
        kmTypes: [],
        kmDevice: '',
        busy: false,
        updating: false,
        errors: {},
        signingError: null,
        showErrorDialog: false,
        showServerSelect: false,
        showPassword: false,
        waiting: true,
        itsMobile: false,
        key: '',
        init: false,
    };

    signer: HardwareSigner;

    componentDidMount() {
        Eds.init(KEY_TYPES.HARDWARE).then(signer => {
            this.signer = signer;

            this.setState(state => ({...state, init: true, error: this.signer?.error}));

            this.updateDevices();
        }, (error) => {
            this.setState(state => ({ ...state, error }))
        })
    }

    updateDevices = (): void =>{
        if (this.state.updating) {
            return;
        }
        this.setState(state => ({...state, updating: true, kmTypes: []}), async () => {
            const kmTypes = await this.signer.getKMTypes();
            this.setState(state => ({...state, kmTypes: kmTypes.filter(({ devices }) => devices && devices.length), updating: false}));
        });
    };

    handleChange = (name: string) => ({target: {value}}: { target: { value: string } }) => this.setState({[name]: value} as any, () => {
        const {errors} = this.state;
        delete errors[name];
        this.setState(state => ({...state, errors}));
    });

    handleKeyChange = ({target}: any) =>
        target.files.length && this.setState(state => ({...state, key: target.files[0]}), () => {
            const {errors, key} = this.state;
            delete errors[key];
            this.setState(state => ({...state, errors}));
        });

    tryToSubmit = ({key}: { key: string }) => key === 'Enter' && this.handleSelectKey();

    handleClose = () => this.setState(state => ({...state, showErrorDialog: false}));

    handleSelectKey = async () => {
        const {t, onSelectKey} = this.props;
        if (!onSelectKey) {
            return;
        }

        const {server, kmType, kmDevice, password} = this.state;
        const errors = this.validate();

        if (Object.keys(errors).length) {
            this.setState(state => ({...state, errors, waiting: false}));
            return;
        }

        this.setState(state => ({busy: true, waiting: true, ...state}));
        setTimeout(() => this.setState(state => ({...state, waiting: false})), 20000);

        const readKeyOnServer = async (serverIndex: number, iterate = true): Promise<any> => {
            const acskServer = edsService.CA[serverIndex];
            if (!acskServer) {
                return null;
            }

            try {
                await this.signer.execute('setServer', acskServer);
                const encodedKey = await this.signer.execute('ReadHardwareKey', kmType, kmDevice, password);
                return encodedKey;
            } catch (e) {
                if (!iterate || serviceMessages.includes(e.message)) {
                    throw e;
                }
                return readKeyOnServer(serverIndex + 1, iterate);
            }
        };

        try {
            const privateKey = await readKeyOnServer(server && (server - 1), !server);

            if (privateKey === null) {
                this.setState(state => ({
                    ...state,
                    busy: false,
                    errors: {server: t('CantDetectACSK')},
                    showServerSelect: true,
                }));
                return;
            }

            await onSelectKey(this.signer, () => {
                return this.signer.execute('ResetPrivateKey')
            });
        } catch (e) {
            this.setState(state => ({
                ...state,
                signingError: (e instanceof TimeoutError) ? t(e.message) : e.message,
                showErrorDialog: true,
                busy: false,
            }));
        }

        this.setState(state => ({...state, busy: false}));
    };

    validate() {
        const {t} = this.props;
        const {server, kmType, kmDevice, password} = this.state;
        const errors = {} as Record<string, string>;

        if (server === null) {
            errors.server = t('SelectServer');
        }

        if (kmType === '') {
            errors.kmType = t('SelectType');
        }

        if (kmDevice === '') {
            errors.kmDevice = t('SelectDevice');
        }

        if (!password) {
            errors.password = t('FillPassword');
        }

        return errors;
    }

    get devices(): KMDevice[] {
        return this.state.kmTypes.find(({index}) => index === this.state.kmType)?.devices || [];
    }

    render() {
        const {
            t,
            onClose,
            classes,
            setId,
            config
        } = this.props;

        const {
            updating,
            server,
            kmType,
            kmDevice,
            password,
            busy,
            errors,
            signingError,
            showErrorDialog,
            waiting,
            showServerSelect,
            showPassword,
            error,
            init,
            kmTypes,
        } = this.state;

        const warningPaper = (causeOfError: string, text = '') => (
            <Typography
                //@ts-ignore
                variant="h5"
                gutterBottom={true}
                id={setId('warning-text')}
                className={classes.errorText}
            >
                {text || t(`HardwareKeySignMethodNotSupported${causeOfError}`)}
            </Typography>
        );

        if (error && !config.isMobile) {
            return warningPaper('Browser', renderHTML(error));
        }

        if (config.isMobile) {
            return warningPaper('Mobile');
        }

        if (!init) {
            return (waiting ? <Preloader/> : warningPaper('BROWSER'));
        }

        return (
            <>
                <DialogContent className={classes.content}>
                    <FormControl
                        fullWidth={true}
                        className={classes.formControl}
                        id={setId('form')}
                    >
                        {(errors.server || showServerSelect) ? (
                            <TextField
                                id={setId('server')}
                                select={true}
                                label={t('ACSKServer')}
                                value={server || 0}
                                error={!!errors.server}
                                onChange={this.handleChange('server')}
                                margin="normal"
                                disabled={busy}
                                helperText={errors.server}
                                SelectProps={{MenuProps: {className: classes.menu}}}
                            >
                                <MenuItem
                                    value={0}
                                    id={setId(`server-autodetect`)}
                                    className={classes.menuItem}
                                >
                                    {t('ACSKAutoDetect')}
                                </MenuItem>
                                {edsService.CA.map((option, index) => {
                                    const name = option.issuerCNs[0];
                                    return (
                                        <MenuItem
                                            key={index}
                                            value={index + 1}
                                            id={setId(`server-${name}`)}
                                            className={classes.menuItem}
                                        >
                                            {name}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        ) : null}
                        <DeviceSelect
                            kmType={kmType}
                            kmDevice={kmDevice}
                            kmTypes={kmTypes}
                            updating={updating}
                            onUpdate={this.updateDevices}
                            onChange={this.handleChange}
                            error={errors.kmType}
                        />
                        {kmType !== '' && (
                            <TextField
                                id={setId('device')}
                                select={true}
                                label={t('SelectKmDevice')}
                                className={classes.textField}
                                value={kmDevice}
                                onChange={this.handleChange('kmDevice')}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu
                                    }
                                }}
                                disabled={busy || this.devices.length === 1}
                                margin="normal"
                                error={!!errors.kmDevice}
                                helperText={errors.kmDevice}
                            >
                                {this.devices.map(({name, index}) => (
                                    <MenuItem
                                        key={index}
                                        value={index}
                                        id={setId(`device-${index}`)}
                                        className={classes.menuItem}
                                    >
                                        {name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                        <TextField
                            id={setId('password')}
                            label={t('Password')}
                            value={password || ''}
                            error={!!errors.password}
                            onKeyPress={this.tryToSubmit}
                            onChange={this.handleChange('password')}
                            margin="normal"
                            type={showPassword ? 'text' : 'password'}
                            disabled={busy}
                            helperText={errors.password}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => this.setState(({
                                                                              showPassword,
                                                                              ...state
                                                                          }) => ({showPassword: !showPassword, ...state}))}
                                        >
                                            {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </FormControl>
                    {config.eds.useProxySettings && <ProxySettings signer={this.signer} busy={busy}/>}
                </DialogContent>
                {busy && <LinearProgress/>}
                <DialogActions className={classes.actions}>
                    {onClose && (
                        <Hidden smDown={true} implementation="css">
                            <Button
                                size="large"
                                onClick={onClose}
                                disabled={busy}
                                id={setId('cancel-button')}
                            >
                                {t('Cancel')}
                            </Button>
                        </Hidden>
                    )}
                    <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={this.handleSelectKey}
                        disabled={busy}
                        id={setId('sign-button')}
                    >
                        {t('Sign')}
                    </Button>
                    <div className={classes.grow}/>
                    <StaticLinks/>
                </DialogActions>
                <Dialog
                    open={showErrorDialog}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    id={setId('dialog')}
                    className={classes.dialog}
                >
                    <DialogTitle
                        id={setId('dialog alert-dialog-title')}
                        className={classes.dialogContentWrappers}
                    >
                        {t('SigningDataError')}
                    </DialogTitle>
                    <DialogContent className={classes.dialogContentWrappers}>
                        <DialogContentText id={setId('dialog alert-dialog-description')}>
                            {signingError}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.dialogContentWrappers}>
                        <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            onClick={this.handleClose}
                            autoFocus={true}
                            id={setId('close-button')}
                        >
                            {t('CloseDialog')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

const styled = withStyles(styles)(HardwareKeySignForm);
const configured =  withConfig(styled as any);
export default translate('SignForm')(configured);
