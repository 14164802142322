import React, { Component } from 'react';
import setComponentsId from 'helpers/setComponentsId';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import { Snackbar, Button, withStyles } from '@material-ui/core';
import style from 'assets/jss';
import {RootState} from "../../reducers";
import { signUpConfirmation, getAuth } from 'actions/auth';

import config from 'config';
import {Values} from "./components/RegisterForm";
import Layout from 'layouts/leftSidebar';
import BlockScreen from 'components/BlockScreen';
import RegisterForm from './components/RegisterForm';

interface RegisterPageProps extends BaseProps{
    values: Required<Values>
}

class RegisterPage extends Component<RegisterPageProps>{
    state = {
        values: this.props.values,
        error: '',
        blockScreen: true
    };

    componentDidMount = async () => {
        const { values } = this.state;

        if (!config.FORCE_REGISTER) {
            this.setState({ blockScreen: false });
            return;
        }

        this.handleSubmit(values);
    }

    handleSubmit = async ({ agreement, ...values }: {agreement: Values['agreement']}) => {
        this.setState({ blockScreen: true });
        const { success, redirect, err, message } = await signUpConfirmation(values);
        if (success) {
            // this.setState({ blockScreen: false });
            return window.location.replace(redirect);
        }
        const error = message || err;

        if (!error) {
            await getAuth();
            const { success, redirect } = await signUpConfirmation(values);
            if (success) {
                return window.location.replace(redirect);
            }
        }

        return this.setState({ error, blockScreen: false }, () => console.log(error));
    };

    closeError = () => this.setState({ error: '', blockScreen: false });

    render() {
        const { setId, t, classes } = this.props;
        const { values, error, blockScreen } = this.state;

        if (blockScreen) {
            return <BlockScreen open={true} />;
        }

        return (
            <Layout setId={(elementName: string)=> setId(`left-side-bar-${elementName}`)}>
                <BlockScreen open={blockScreen} />
                {error &&
                    <Snackbar
                        id={setId('error')}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true}
                        message={
                            <span id="message-id"
                                  //@ts-ignore
                                  name={error}
                            >
                                {/* {t('ERROR')} */}
                                {t(error)}
                            </span>
                        }
                        action={[
                            <Button
                                key="close-error"
                                //@ts-ignore
                                color="yellow"
                                size="small"
                                onClick={this.closeError}
                                className={classes.smallButton}
                            >
                                OK
                            </Button>
                        ]}
                    />
                }
                <RegisterForm
                    values={values}
                    onSubmit={this.handleSubmit}
                    setId={(elementName: string) => setId(`register-form-${elementName}`)}
                />
            </Layout>
        );
    }
    static defaultProps = {
        setId: setComponentsId('app'),
        values: {}
    };
}


const styled = withStyles(style)(RegisterPage);
const translated = translate('RegisterForm')(styled);

function mapStateToProps(state: RootState) {
    return { auth: state.auth };
}

export default connect(mapStateToProps)(translated);
