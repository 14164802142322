import React  from 'react';
import {
    FormGroup,
    withStyles
} from '@material-ui/core';
import {createStyles} from "@material-ui/core/index";

interface FormElementsGroupProps extends BaseProps{
    label: any
}

const styles = createStyles({
    root: {
        border: '#aaaaaa 1px solid',
        borderRadius: 4,
        padding: '0 10px',
        marginTop: 10,
        marginBottom: 10
    },
    label: {
        background: '#eee',
        color: 'rgba(0, 0, 0, 0.54)',
        margin: '-14px 0 0 0',
        position: 'absolute',
        padding: '0 8px',
        cursor: 'default',
        border: 'rgba(0, 0, 0, 0.54) 1px solid',
        borderRadius: 3
    }
});

const FormElementsGroup: React.FC<FormElementsGroupProps> = ({classes, label, children}) => (
    <FormGroup classes={{root: classes!.root}}>
        {label && <div className={classes!.label}>{label}</div>}
        {children}
    </FormGroup>
);
export default withStyles(styles)(FormElementsGroup)