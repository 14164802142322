import React, {Component} from 'react';
import setComponentsId from 'helpers/setComponentsId';
import {connect} from 'react-redux';
import {RootState} from "../reducers";
import {getAuth} from 'actions/auth';
import {translate} from 'react-translate';
import LoginPage from 'pages/Login';
import RegisterPage from 'pages/Register';
import TwoFactorAuth from 'pages/TwoFactorAuth';
import Agreement from 'pages/Agreement';
import BlockScreen from 'components/BlockScreen';
import EmptyPage from 'components/EmptyPage';
import {withStyles} from '@material-ui/core';
import {withConfig, WithConfigProps} from "../components/Config";

interface AppProps extends BaseProps, WithConfigProps {
    provider?: Providers,
    user?: object,
    info?: UserInfo,
    redirect: string,
    twoFactorAuthNeeded?: boolean,
    DBError: boolean
}

const styles = {
    wrap: {
        paddingTop: 64,
        paddingLeft: 260,
        '@media (max-width: 959px)': {
            paddingLeft: 0
        }
    }
};

class App extends Component<AppProps, {}> {
    state = { ready: false, agreement: false };

    componentWillMount = () => {
        getAuth().then(() => this.setState({ ready: true })).catch(e => null);
    };

    componentWillReceiveProps = ({ redirect }) => {
        const { BACKEND_URL } = this.props.config;

        if (redirect) {
            document.location.href = BACKEND_URL +
                (BACKEND_URL.charAt(BACKEND_URL.length - 1) !== '/' ? '/' : '') +
                redirect.split('/').filter(Boolean).join('/');
        }
    };

    render() {
        const { provider, user, redirect, info, twoFactorAuthNeeded, setId, DBError, classes, t, config } = this.props;
        const { ready, agreement } = this.state;
        if (DBError) {
            return (
                <div className={classes!.wrap}>
                    <EmptyPage
                        title={t!('ERROR')}
                        description={t!('ERROR_DESCRIPTION')}
                    />
                </div>
            );
        }

        if (!ready || redirect) {
            return <BlockScreen open={true}/>;
        }

        if (info && twoFactorAuthNeeded) {
            return (
                <TwoFactorAuth
                    values={info}
                    setId={(elementName: string) => setId ? setId(`two-factor-${elementName}`) : ''}
                />
            );
        }

        if (config.PERSONAL_DATA_AGREEMENT && !agreement && user){
            return (
                <Agreement
                    setId={(elementName: string) => setId ? setId(`agreement-${elementName}`) : ''}
                    onContinue={() => this.setState({agreement: true})}
                />
            )
        }

        if (provider && user) {
            return (
                <RegisterPage
                    values={user}
                    setId={(elementName: string) => setId ? setId(`register-${elementName}`) : ''}
                />
            );
        }

        return <LoginPage setId={(elementName: string) => setId ? setId(`login-${elementName}`) : ''} />;
    }
    static defaultProps: Partial<AppProps> = {
        setId: setComponentsId('app'),
        // provider: null,
        // user: null,
        // info: null,
        // redirect: '',
        // twoFactorAuthNeeded: false
    };
}

const translated = translate('App')(App);
const configured = withConfig(translated);
const styled = withStyles(styles)(configured);
export default connect(({ auth }: RootState) => (auth))(styled);
