/**
 * Service for working with electron digital signature (EDS)
 */
// eslint-disable-next-line import/no-webpack-loader-syntax
import EDSWorker from 'worker-loader!./worker';
import customPassword from 'helpers/customPassword';
import {
    CA,
    IActionRunner,
    Initializable,
    IProxySettingsConsumer,
    ISignerConfig,
    ISignerProxyConfig
} from "./common";
import {KEY_TYPES} from "../../consts";
import {EdsException} from "./EdsException";

class FileSigner implements IActionRunner, IProxySettingsConsumer, Initializable {
    worker: EDSWorker;

    proxySettings: ISignerProxyConfig;
    private list: Readonly<CA[]>;

    constructor(private config: ISignerConfig, list: Readonly<CA[]>) {
        this.proxySettings = config.proxySettings;
        this.worker = new EDSWorker();
        this.list = list;
    }

    async init(): Promise<void> {
        await this.execute('init', {
            list: this.list,
            proxySettings: this.proxySettings,
            edsServiceUrl: this.config.signDataUrl
        });
    }

    send(message: any) {
        this.worker.postMessage(message)
    };

    execute<T = any>(cmd, ...commandData: any[]): Promise<T> {
        return new Promise((resolve, reject) => {
            const commandId = customPassword();
            const messageListener = ({data: result}: any) => {
                if (result.commandId !== commandId) {
                    return;
                }

                this.worker.removeEventListener('message', messageListener, true);
                const {error, data: resultData} = result;

                if (error) {
                    reject(new EdsException(error, resultData, KEY_TYPES.FILE));
                } else {
                    resolve(resultData);
                }
            };
            this.worker.addEventListener('message', messageListener, false);
            this.send({cmd, commandId, commandData});
        });
    }
}

export default FileSigner;
