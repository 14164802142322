import React from 'react';
import setComponentsId from 'helpers/setComponentsId';
import Layout from 'layouts/fullPage';

const Terms: React.FC<BaseProps> = ({ setId, children }) => (
    <Layout setId={setId}>
        <iframe style={{ width: '100%', minHeight: '50vh', border: 'none' }} src="/terms.html" />

        {children}
    </Layout>
);

Terms.defaultProps = {
    setId: setComponentsId('terms')
};

export default Terms;
