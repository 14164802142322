import React from 'react';
import { withStyles, createStyles } from '@material-ui/core';
import setId from 'helpers/setComponentsId';
import svgIcon from 'assets/img/gear-loading-icon.svg';

const styles = createStyles({
    '@keyframes rotate': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' }
    },
    mainWrapper: {
        textAlign: 'center',
        padding: '20px 20px 10px'
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        padding: '20px 20px 10px',
        height: '100%'
    },
    nopadding: {
        padding: 10
    },
    box: {
        position: 'relative',
        width: '136px',
        height: '136px',
        borderRadius: '30%',
        overflow: 'hidden',
        margin: '0 auto'
    },
    content: {
        background: '#fff',
        position: 'absolute',
        top: '2px',
        bottom: '2px',
        left: '2px',
        right: '2px',
        margin: 'auto',
        borderRadius: '30%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 6
    },
    blackUnderlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        margin: 'auto',
        background: '#000',
        borderRadius: '30%',
        overflow: 'hidden',
        zIndex: 4
    },
    rotator: {
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        margin: 'auto',
        borderRadius: '50%',
        animationName: 'rotate',
        animationDuration: '2s',
        animationTimingFunction: 'linear',
        animationIterationCount:'infinite'
    },
    border: {
        position: 'absolute',
        width: '75px',
        height: '75px',
        top: '-28px',
        left: 0,
        right: 0,
        margin: 'auto',
        borderRadius:'50%',
        background: 'linear-gradient(90deg, #8edacb 0, #7abace 100%)',
        zIndex: 5
    },
    diiawrapper: {
        background: '#fff',
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

const DefaultLoader = () => (
    <img src={svgIcon} alt="Loading..." id={setId('preloader')('')}/>
);

interface PreloaderProps { classes: Record<string, string> }

const Preloader: React.FC<PreloaderProps> = ({classes}) => (
    <div
        className={classes.mainWrapper} 
        id={setId('preloader-wrap')('')}
    >
        <DefaultLoader />
    </div>
);

export default withStyles(styles)(Preloader);
