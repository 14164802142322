import {load} from 'config';
import * as Sentry from "@sentry/browser";
import { init } from "./layout";
import EDS from "services/eds";
import themes from "./themes";

console.info("VERSION", process.env.VERSION);

const mountPoint = document.getElementById('root');
if(!mountPoint) {
    throw new Error('no mount point found for application');
}

document.title = "Loading...";

const createFavicon = (icon: string) => {
    const link = document.createElement('link');

    link.rel = "shortcut icon";
    link.href = icon;

    return link;
}

load().then(async (config) => {
        const {APP_ENV, APP_TITLE, SENTRY_DSN, APP_NAME } = config
        const { theme } = await themes(APP_NAME);

        if(!theme) throw new Error(`theme is not defined: ${APP_NAME}`);

        document.title = APP_TITLE;
        document.head.appendChild(createFavicon(theme.favicon));

        Sentry.init({
            enabled: !!SENTRY_DSN,
            dsn: SENTRY_DSN,
            environment: APP_ENV,
            release: `${APP_NAME}@${process.env.VERSION}`,
            ignoreErrors: [
                '401 unauthorized',
                '404 not found',
                'NetworkError when attempting to fetch resource.',
                'Failed to fetch',
                'Перелік закінчено',
                'NS_BINDING_ABORTED:'
            ]
        });

        Sentry.configureScope(scope => {
            scope.setTag('project', `${config.APP_NAME}-${config.APP_ENV}`);

        })

        EDS.config = config.eds;
        await EDS.loadEDS(config.eds.serverListUrl);

        init(config, mountPoint, theme);
    })
    .catch((e) =>{
        console.group('INIT_APP');
        console.error(e);
        console.groupEnd();

        document.title = "ERROR";
        mountPoint.innerHTML = "Can not load without config. Please, contact to administrator.";
    })