import * as Sentry from '@sentry/browser';
interface Scope extends Sentry.Scope{ error:Error }
export default async (error: Error, url: string, method: string, body: string | File) => {
    const  {message } = error;
    // const {name, version} = packageJson;
    await Sentry.withScope((scope) => {
        scope.setTag('signer_type', `${message}`);
        if (url) scope.setTag('url', url);
        if (method) scope.setTag('method', method);
        scope.setLevel('warning' as Sentry.Severity);
        (scope as Scope).error = error;
        if (body) scope.setExtra('body', body);
        Object.keys(error).forEach(key => scope.setExtra(key, error[key as keyof Error]));
        Sentry.captureException(error);
    });
};
interface MyError extends Error {
    serverMessage?: string;
    headers?: string
}
export const checkError = (response: any, request = {}) => {
    const {status} = response;
    let message = response.message && typeof response.message === 'object' && response.message.message ? response.message.message : response.message || response.statusText;
    const serverMessage = message;
    let myError: Error | boolean = false;
    switch (status) {
        case 401: message = '401 unauthorized';
            break;
        case 403:
        case 404: message = 'Openstack - 404 File not found';
            break;
        case 503: message = '503 Service Temporarily Unavailable';
            break;
        case 504: message = 'Openstack - 504 Gateway Time-out';
            break;
        case 500:
            if (message && typeof message === 'string' && message.includes('Invalid URI')) {
                message = message.includes('_preview') ? 'Openstack - Preview not formed' : 'Openstack - URL not formed';
            }
            break;
        default:
            break;
    }
    if (message) {
        let myError: MyError
        if (response instanceof Error) {
            myError = response;
            myError.message = `API: ${message}`;
        } else if (response.message && response.message instanceof Error) {
            myError = response.message;
            (myError as Error).message = `API: ${message}`;
        } else {
            myError = new Error(`API: ${message}`);
        }
        (myError as MyError).serverMessage = serverMessage;
        [{name: 'response', value: response}, {name: 'request', value: request}].forEach((error) => {
            (Object.keys(error.value) as Array<keyof MyError>).forEach(key => {
                if (key !== 'message' && key !== 'headers' && typeof myError[key] !== 'object') {
                    myError[key] = error.value[key];
                }
                if (typeof myError[key] === 'object') {
                    Object.keys(myError[key]).forEach((k) => {
                        (myError as any)[`${key}-${k}`] = (myError as any)[key][k];
                    });
                }
            });
        });
    }
    return myError;
};

