import store from 'store';
import fetch from 'isomorphic-fetch';
import {AppDispatch} from "store";
import promiseChain from 'helpers/promiseChain';
import {Actions, ActionTypes} from "../../actions/types";

import ApiException, { checkError } from './ApiException';

interface RequestBody {
    url: string;
    method: string;
    headers: Headers;
    credentials: RequestCredentials;
    body?: string | File
}

let fetchErrorCount = 0;


const parseFetchResponse = async (response: Response) => response.json().then(resp => (resp));

const addMeta = (body: any) => {
    if (body.data) {
        if (body.data.result) {
            body.data = body.data.result;
        }
        if (!body.data.meta) {
            body.data.meta = body.meta;
        }
        return body.data;
    }
    return body;
};

const getHeaders = (method: string) => {
    const { token } = store.getState().auth || {};
    const headers = new Headers();
    headers.append('Access-Control-Request-Method', method);
    headers.append('Access-Control-Request-Headers', method);
    headers.append('Content-Type', 'application/json');
    headers.append('token', token);
    return headers;
};

const createRequestBody = (method: string, url: string): RequestBody => {
    const headers = getHeaders(method);

    return {
        url,
        method,
        headers,
        credentials: 'same-origin'
    };
};


const getResponceBody = async (response: Response, request: RequestBody) => {
    const { ok } = response;
    let error: Error | Response | boolean = false;
    let detail = '';
    const contentType = response.headers && response.headers.get('content-type');
    const correctContentType = contentType && typeof contentType === 'string';
    const isJSON = contentType.includes('application/json');
    if (!ok) {
        if (isJSON) {
            const parsed = await parseFetchResponse(response);
            ({ error, detail } = parsed);
            if (!error && detail) {
                error = new Error(detail);
            }
            if (error) {
                let error = {} as {type: string, url: string, status: number, statusText: string};
                error.type = response.type;
                error.url = response.url;
                error.status = response.status;
                error.statusText = response.statusText;
            }
        } else {
            error = response;
        }

        error = checkError(error || response, request);
        if (error) {
            throw error;
        }
    }

    if (isJSON) {
        return response.json();
    }
    if (correctContentType && contentType.includes('text/html')) {
        return response.text();
    }
    return response.blob();

};

const updateBodyMeta = (body: any, request: RequestBody) => {
    if (body.error) {
        const { error } = body;
        Object.keys(body).forEach((key) => {
            error[key] = body[key];
        });
        throw checkError(body.error, request);
    }

    return body.meta || (body.data && body.data.meta) ? addMeta(body) : body.data || body;
};

const checkResponse = (action: ActionTypes, dispatch: AppDispatch, request: RequestBody) => (response: any) => {
    const { url, body, method } = request;

    if (response && response.error) {
        const { error } = response;
        Object.keys(response).forEach((key) => {
            error[key] = response[key];
        });
        throw checkError(response.error, request);
    }

    dispatch({ type: `${action}_SUCCESS` as any, payload: response, url, method, body });
    return response;
};

const responseFail = (action: ActionTypes, dispatch: AppDispatch, request: RequestBody, createReq: typeof createRequest, payload: unknown) => (error: Error) => {
    const { url, body, method } = request;

    dispatch({ type: `${action}_FAIL` as any, payload: error, url, method, body });
    if (error.message && error.message.includes('Failed to fetch') && fetchErrorCount < 16) {
        fetchErrorCount += 1;
        request.headers = getHeaders(method);
        return createReq(request, action, dispatch, payload);
    }
    fetchErrorCount = 0;
    ApiException(error, url, method, body);
    error.message = (error as any).serverMessage || error.message;

    // return Promise.reject(error);
    if (error.message && error.message.includes('ORA') && !error.message.includes('ADD_FILEDOC')) {
        dispatch({ type: Actions.DB_ERROR, payload: true, url, method, body });
    }
    if (error.message && error.message.includes('503')) {
        dispatch({ type: Actions.ERROR_503, payload: true, url, method, body });
    }
    return error;
};

function createRequest(request: RequestBody, action: ActionTypes, dispatch: AppDispatch, payload: unknown): Promise<any> {
    const { url, ...config } = request;
    const { method } = request;
    // PLEASE DONT REMOVE OR RENAME THIS ACTION.
    // THIS ACTION USED BY THE PAST IN reducers/datafetched
    dispatch({ type: action + '_LOADING' as any, payload, body: request.body, url, method });

    return promiseChain([
        () => fetch(url, config),
        getResponceBody,
        updateBodyMeta,
        checkResponse(action, dispatch, request)
    ]).catch(responseFail(action, dispatch, request, createRequest, payload));
}

export function get(url: string, action: ActionTypes, dispatch: AppDispatch) {
    const request = createRequestBody('get', url);
    return createRequest(request, action, dispatch, {});
}

export function post<Body>(url: string, body: Body, action: ActionTypes, dispatch: AppDispatch) {
    const request = createRequestBody('post', url);
    request.body = JSON.stringify(body);

    return createRequest(request, action, dispatch, body);
}

export function upload<Params>(url: string, file: File, params: Params, action: ActionTypes, dispatch: AppDispatch) {
    const request = createRequestBody('post', url + '?' + Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&'));
    request.body = file;
    request.headers.set('Content-Type', file.type);

    return createRequest(request, action, dispatch, file);
}

export function put<Body>(url: string, body: Body, action: ActionTypes, dispatch: AppDispatch) {
    const request = createRequestBody('put', url);
    request.body = JSON.stringify(body);

    return createRequest(request, action, dispatch, body);
}

export function del(url: string, action: ActionTypes, dispatch: AppDispatch) {
    const request = createRequestBody('delete', url);

    return createRequest(request, action, dispatch, {});
}