import React from 'react';

import {
    withStyles,
    Dialog
} from '@material-ui/core';

import Preloader from 'components/Preloader';

interface BlockScreenProps extends BaseProps{
    open: boolean;
    transparentBackground?: boolean
}

const styles = {
    dialog: {
        '& > div': {
            background: 'transparent',
            transition: 'none'
        }
    },
    dialogPaper: {
        background: 'transparent',
        boxShadow: 'none'
    }
};

const BlockScreen: React.FC<BlockScreenProps> = ({
    classes,
    open,
    transparentBackground = false
}) => {
    if (!open) return null;
    return (
        <Dialog
            open={open}
            maxWidth="md"
            className={transparentBackground ? classes!.dialog : ''}
            PaperProps={{
                className: classes!.dialogPaper
            }}
        >
            <Preloader/>
        </Dialog>
    );
};

export default withStyles(styles)(BlockScreen) as typeof BlockScreen;
