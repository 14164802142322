import {KEY_TYPES} from "../../consts";
import * as Sentry from "@sentry/browser";

export class EdsException extends Error {
    constructor(errorText: string, params = {}, signerType: KEY_TYPES){
        super(errorText);

        Sentry.withScope(scope => {
            scope.setTag('signer_type', signerType);
            scope.setLevel('warning' as Sentry.Severity);

            (Object.keys(params) as Array<keyof typeof params>).forEach((paramKey) => {
                scope.setExtra(paramKey, params[paramKey]);
            });

        });
    }
}