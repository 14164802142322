import React from 'react';
import ReactDOM from 'react-dom';
import { TranslatorProvider } from 'react-translate';
import { Provider } from 'react-redux';
import { ConfigProvider } from "./components/Config";

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';

import 'assets/css/material-dashboard-react.css';
import 'assets/css/material-dashboard-react-elements.css';

import {routes} from 'routes';
import i18n from './translations/uk_UA.json';

import {Router, Route, Switch, Redirect} from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Auth from 'components/Auth';
import store from 'store';

import GTM from 'components/GoogleTagManager';

import {IConfig} from 'config';
import {CustomTheme} from 'themes';

export const init = ((config: IConfig, mountPoint: HTMLElement, theme: CustomTheme) => {
    const hist = createBrowserHistory();

    const withRouter = <Router history={hist}>
        <Switch>
            {routes(theme).map(props => (React.createElement(Route, props)))}
            <Redirect to='/' />
        </Switch>
    </Router>
    const withGoogleAnalytic = <GTM GTM_KEY={config.GTM_KEY}>{withRouter}</GTM>;
    const withAuth = <Auth>{withGoogleAnalytic}</Auth>;
    const withTranslations = <TranslatorProvider translations={i18n}>{withAuth}</TranslatorProvider>
    const withStoreProvider = <Provider store={store}>{withTranslations}</Provider>;
    const withThemeProvider = <MuiThemeProvider theme={createMuiTheme(theme)}>{withStoreProvider}</MuiThemeProvider>;
    const withStyledUi = <MuiPickersUtilsProvider utils={MomentUtils}>{withThemeProvider}</MuiPickersUtilsProvider>;
    const withConfig = <ConfigProvider config={config}>{withStyledUi}</ConfigProvider>

    ReactDOM.render(withConfig, mountPoint);
})