import React, { Component } from 'react';
import setComponentsId from 'helpers/setComponentsId';
import {connect} from 'react-redux';
import {translate} from 'react-translate';
import {RootState} from "../../reducers";
import promiseChain from 'helpers/promiseChain';
import Layout from 'layouts/topHeader';

import {
    withStyles,
    Typography,
    Grid,
    Button,
    TextField
} from '@material-ui/core';

import style from '../../assets/jss';

import {checkSMSCode} from 'actions/auth';

interface TwoFactorAuthPageProps extends BaseProps{
    values: Record<string, string>
}

interface TwoFactorAuthPageState {
    code: string;
    codeError: Error;
    values:Record<string, string>
    checked?: boolean;
    showActivation?: boolean;
}

class TwoFactorAuthPage extends Component<TwoFactorAuthPageProps, TwoFactorAuthPageState> {
    state: TwoFactorAuthPageState = {
        code: '',
        codeError: null,
        values: this.props.values
    };

    handleChangeCode: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
        = ({target: {value}}) => this.setState(state => ({ ...state, code: value, codeError: null}));

    handleActivate = () => promiseChain([
        this.checkCodeValid,
        this.verifyActivationCode,
        () => this.setState(state => ({ ...state, checked: true, showActivation: false}), this.handleFinish)
    ]).catch(error => this.setState(state => ({ ...state, codeError: error})));

    checkCodeValid = async () => {
        const {t} = this.props;
        const {code} = this.state;
        if (!code) {
            throw t('EMPTY_CODE_ERROR');
        }
    };

    handleFinish = () => {}

    verifyActivationCode = async () => {
        const {t} = this.props;
        const {code} = this.state;
        const {success} = await checkSMSCode(code);

        if (!success) {
            throw t('ACTIVATION_CODE_INVALID');
        }

        window.location.href = '/authorise/continue';
    };

    render() {
        const { classes, t, setId } = this.props;

        const { code, codeError, values: { phone } } = this.state;

        return (
            <Layout setId={setId}>
                <Typography
                    variant="headline"
                    gutterBottom={true}
                    id={setId('title')}
                >
                    {t('TITLE')}
                </Typography>
                <Typography
                    variant="subheading"
                    gutterBottom={true}
                    id={setId('sub-title')}
                >
                    {t('SUBTITLE', {phone})}
                </Typography>
                <Grid
                    container={true}
                    spacing={8}
                    id={setId('container')}
                >
                    <Grid
                        item={true}
                        xs={12}
                        sm={6}
                        id={setId('grid')}
                    >
                        <TextField
                            id={setId('code')}
                            name="code"
                            margin="none"
                            value={code}
                            error={!!codeError}
                            helperText={codeError}
                            label={t('ACTIVATION_CODE')}
                            onChange={this.handleChangeCode}
                            className={classes.fullWidth}
                        />
                    </Grid>
                    <Grid
                        item={true}
                        xs={12}
                        sm={6}
                        id={setId('grid-2')}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.fullWidth}
                            onClick={this.handleActivate}
                            //@ts-ignore
                            setId={elementName => setId(`active-${elementName}`)}
                        >
                            {t('ACTIVATE')}
                        </Button>
                    </Grid>
                </Grid>
            </Layout>
        );
    }
    static defaultProps = {
        setId:  setComponentsId('twoFactorAuth'),
        values: {}
    };
}

const styled = withStyles(style)(TwoFactorAuthPage);
const translated = translate('TwoFactorAuthPage')(styled);

function mapStateToProps(state: RootState) {
    return {auth: state.auth};
}

export default connect(mapStateToProps)(translated);
