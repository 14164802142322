import React, {Fragment, useState} from 'react';
import setComponentsId from 'helpers/setComponentsId';
import {KEY_TYPES, READERS} from "../../consts";
import {translate} from 'react-translate';
import {Tab, Tabs, withStyles} from '@material-ui/core';

import FileKeySignForm from './FileKeySignForm';
import HardwareKeySignForm from './HardwareKeySignForm';
import {createStyles} from "@material-ui/core/index";
import Signer from "../../services/eds/signer";
import {withConfig, WithConfigProps} from "../Config";
import {IActionRunner} from "../../services/eds/common";

type DispatchActionProps = {
    signerInitSuccess: () => void;
    signerInitFailed: (payload: unknown) => void;
}

const styles = createStyles({
    tabs: {
        margin: 0
    },
    tab: {
        fontSize: 16,
        '&:first-child': {
            marginLeft: 0,
            paddingLeft: 0
        },
        '@media screen and (max-width: 767px)': {
            fontSize: 14,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            marginRight: 1
        }
    }
});

interface EdsFormProps extends BaseProps, WithConfigProps, DispatchActionProps {
    onSelectKey: (cert?: SignData, signer?: Signer, resetPrivateKey?: () => Promise<void>) => Promise<any>;
    token: string;
}

const getEncodeCert = async (signer, index = 0) => {
    const cert = await signer.execute('EnumOwnCertificates', index);
    if (cert === null) {
        // throw new Error('Ключ не має відповідного сертифікату');
        throw new Error('Сертифікат шифрування відстуній. Зверніться до вашого АЦСК');
    }

    if (cert.keyUsage === 'Протоколи розподілу ключів') {
        return cert;
    }

    return getEncodeCert(signer, index + 1);
};

const EDSForm: React.FC<EdsFormProps> = ({
                                             t, setId = setComponentsId('sign-form'), classes,
                                             token, config,
                                             onSelectKey
                                         }) => {
    const [tab, setTab] = useState<KEY_TYPES>(config.providers.KEY_TYPE || KEY_TYPES.FILE);

    const handleSelectKey = async (signer: IActionRunner, cb) => {
        const signature = await signer.execute('SignData', token, true);
        let signData: SignData = {
            type: READERS.IIT,
            signature,
            token,
        };

        if (config.eds.useEncodeCert) {
            const {issuer, serial} = await getEncodeCert(signer);

            const decodedCert = await signer.execute('GetCertificate', issuer, serial);
            const encodedCert = await signer.execute('Base64Encode', decodedCert);

            signData.encodedCert = encodedCert;
            signData.encodeCertSerial = serial;
        }

        await onSelectKey(signData, cb);
    };

    return (
        <Fragment>
            {!config.providers.KEY_TYPE && (
                <Tabs
                    value={tab}
                    onChange={(event, tab) => setTab(tab)}
                    indicatorColor="primary"
                    textColor="primary"
                    id={setId('tabs')}
                >
                    <Tab
                        label={t('FileKeySignMethod')}
                        id={setId('tab-file-key')}
                        className={classes.tab}
                        value={KEY_TYPES.FILE}
                    />
                    <Tab
                        label={t('HardwareKeySignMethod')}
                        id={setId('tab-hardware-key')}
                        className={classes.tab}
                        value={KEY_TYPES.HARDWARE}
                    />
                </Tabs>
            )}
            {((!config.providers.KEY_TYPE && tab === KEY_TYPES.FILE) || config.providers.KEY_TYPE === KEY_TYPES.FILE) && (
                <FileKeySignForm
                    onSelectKey={handleSelectKey}
                    setId={elementName => setId(`file-key-${elementName}`)}
                />
            )}
            {((!config.providers.KEY_TYPE && tab === KEY_TYPES.HARDWARE) || config.providers.KEY_TYPE === KEY_TYPES.HARDWARE) && (
                <HardwareKeySignForm
                    onSelectKey={handleSelectKey}
                    setId={elementName => setId(`hardware-key-${elementName}`)}
                />
            )}
        </Fragment>
    );
};

const styled = withStyles(styles)(EDSForm);
const configured = withConfig(styled as any)
export default translate('SignForm')(configured);
