import React from 'react';
import setComponentsId from 'helpers/setComponentsId';
import Layout from 'layouts/fullPage';

interface StaticPageContentProps extends Omit<BaseProps,'classes'>{
    fields: string[];
    title?: string
}

const StaticPageContent: React.FC<StaticPageContentProps> = ({ setId, t, fields, title }) => (
    <Layout setId={setId}>
        <h3>{t(title!)}</h3>
        {fields.map((mode, index) => (
            <p
                className="ql-align-justify"
                id={setId(`p-${index + 1}`)}
                key={setId(`p-${index + 1}`)}
                style={{ textAlign: 'justify' }}
            >
                {mode === 'br' && <br/>}
                {(mode === 'text' || mode === 'textMail') && t(`TEXT${index + 1}`)}
                {mode === 'strong' && <strong>{t(`TEXT${index + 1}`)}</strong>}
                {mode === 'textMail' &&
                    <span>
                        <a href={`mailto:${t(`TEXT${index + 2}`)}`}>{t(`TEXT${index + 2}`)}</a>.
                    </span>
                }
            </p>
        ))}
    </Layout>
);

// StaticPageContent.propTypes = {
//     setId:  PropTypes.func,
//     t:      PropTypes.func.isRequired,
//     fields: PropTypes.array,
//     title:  PropTypes.string
// };

StaticPageContent.defaultProps = {
    setId:  setComponentsId('static-page'),
    fields: [],
    title:  'TITLE'
};

export default StaticPageContent;
