import React from 'react';
import { translate } from 'react-translate';
import { withStyles, Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import store from 'store';
import * as api from './../../../services/api';
import logo from './../../../assets/img/gov-id-logo.svg';
import {createStyles} from "@material-ui/core/index";

type Props = {
    classes: Record<string, string>,
    t: (key: string) => string;
}

class GovIdButton extends React.Component<Props, never> {
    componentDidMount() {
        const params = window.location.search;

        if (!params.length) return;

        const param = params.replace('?', '').split('&');

        param.forEach(item => {
            const paramName = item.split('=')[0];
            const paramValue = item.split('=')[1];

            if (paramName !== 'code') return;

            const data = {
                'code': paramValue
            }

            api.post('authorise/govid', data, 'SET_GOV_ID', store.dispatch).then(result => {
                console.log('RESULT', result);
                if (result instanceof Error) {
                    return null;
                } else {
                    window.location.reload();
                }
            })
        })
    }

    handleClick = () => {
        api.get('authorise/govid/info', 'GET_GOV_ID', store.dispatch).then(result => {
            window.location.replace(result.totalAuthUrl)
        });
    };

    render() {
        const { t, classes } = this.props;
        const { codeError } = this.state || {};

        return (
            <React.Fragment>
                {codeError ? (
                    <React.Fragment>
                        <div className={classes.disclaimer}>
                            <Typography variant="headline" className={classes.discText}>{t('NOIPN')}</Typography>
                            <Typography variant="subheading">{t('NOIPN_DESCRIPTION')}</Typography>
                        </div>
                    </React.Fragment>
                ) : null}
                <div className={classes.wrap}>
                    <Button
                        fullWidth={true}
                        className={classes.authBtn}
                        onClick={this.handleClick}
                    >
                        <span className={classes.text}>{t('AuthWith')}{' '}</span>
                        <img src={logo} alt="Logo" />
                    </Button>
                    <Typography
                        className={classes.description}
                        variant="caption"
                        //@ts-ignore
                        display="block"
                        gutterBottom
                    >
                        {t('AuthWithText')}
                    </Typography>
                </div>
            </React.Fragment>
        );
    }
    static propTypes;
}

const styles = theme => createStyles({
    wrap: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20,
        ['@media (max-width:610px)']: { // eslint-disable-line no-useless-computed-key
            display: 'block'
        }
    },
    description: {
        margin: 0,
        lineHeight: '18px',
        paddingLeft: 20,
        ['@media (max-width:610px)']: { // eslint-disable-line no-useless-computed-key
            paddingLeft: '0',
            lineHeight: 'normal',
            marginTop: 10
        }
    },
    authBtn: {
        minWidth: 240,
        height: 50,
        display: 'flex',
        color: '#fff',
        padding: '10px 15px',
        border: '2px solid transparent',
        background: "linear-gradient(270deg, #314260, #365a96)",
        boxShadow: '0 0 10px #fff',
        borderRadius: 4,
        '&:hover': {
            color: '#fff',
            textDecoration: 'unset',
            background: "linear-gradient(270deg, #204c92, #3a6cbe)",
            boxShadow: '0 0 10px #3a6cbe',
            transition: '0.2s ease-in-out'
        },
        '&:active': {
            border: '2px solid transparent',
            background: "linear-gradient(270deg, #314260, #365a96)"
        },
        '&:focus': {
            color: '#fff',
            textDecoration: 'unset',
            border: '2px solid #498af2',
        },
        '&:visited': {
            color: '#fff',
            textDecoration: 'unset',
        }
    },
    diiaAuthBtn: {
        minWidth: 240,
        height: 50,
        display: 'flex',
        color: '#000',
        border: '2px solid #000',
        boxShadow: '0 0 10px #fff',
        borderRadius: 4,
        '&:hover': {
            transition: '0.2s ease-in-out'
        },
    },
    text: {
        fontSize: '14px',
        paddingRight: '4px',
        lineHeight: '20px',
        fontWeight: 600,
        textTransform: 'initial'
    },
    diiaText: {
        fontSize: '14px',
        paddingRight: '4px',
        lineHeight: '20px',
        fontWeight: 600,
        textTransform: 'initial',
        whiteSpace: 'nowrap',
        letterSpacing: '-0.02em'
    },
    logo: {
        height: 35
    },
    diiaLogo: {
        height: 30,
        paddingLeft: 5
    },
    emptyWrap: {
        marginTop: -50
    },
    diiaDisclaimer: {
        backgroundColor: '#fff7e3',
        padding: '25px 18px',
        marginBottom: 20,
        maxWidth: 1120,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 15
        }
    },
    icon: {
        fontSize: 38,
        marginRight: 10
    },
    discText: {
        fontWeight: 300,
        fontSize: 20
    },
    subheading: {
        display: 'block',
        padding: '15px 0 0 50px'
    },
    disclaimer: {
        backgroundColor: '#fec5c5',
        padding: '25px 18px',
        marginBottom: 20,
        maxWidth: 1120,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 15
        }
    }
});

GovIdButton.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

const translated = translate('LoginPage')(GovIdButton);

export default withStyles(styles)(translated);
