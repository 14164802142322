import {applyMiddleware, createStore} from 'redux';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
import {History} from 'history'
import { composeWithDevTools } from 'redux-devtools-extension';


export default function configureStore(history?: History<unknown>) {
        if (process.env.NODE_ENV !== 'production') {
            return createStore(rootReducer, composeWithDevTools(applyMiddleware(createLogger({ collapsed: true }))));
        }
        return createStore(rootReducer);
}
