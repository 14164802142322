export default {
    //"production", "dev"
    //environment : "dev",
    environment: "production",

    version: "1.0.0b5",

    //Used in case of Agent calls
    caasAgentUrl: "https://local.cipher.kiev.ua:9090/api/v1/",
};
