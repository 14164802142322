import React from 'react';
import {WithStyles, withStyles} from '@material-ui/core';
import translate from 'react-translate/lib/translate';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { WithTheme } from 'themes';

const styles = {
    aboutLinkWrapper: {
        '& > a': {
            display: 'flex'
        }
    }
};

type Props = BaseProps & WithTheme & WithStyles<typeof styles>;

const StaticLinks: React.FC<Props> = (props) => {
    const { classes, theme: { staticLinks } = {} } = props;

    if (!staticLinks || !Array.isArray(staticLinks)) {
        return null;
    }

    return (
        <div className={classes.aboutLinkWrapper}>
            {staticLinks.map(({ title, link }, key) => (
                <a
                    key={key}
                    href={link}
                    target="_blank"
                    className={classes.aboutLink}
                    rel="noopener noreferrer"
                >
                    <HelpOutlineIcon />
                    {title}
                </a>
            ))}
        </div>
    );
};


const styled = withStyles(styles, {
    withTheme: true
})(StaticLinks as any);
export default translate('SignForm')(styled);
