import FileSigner from 'services/eds/signer';
import HardwareSigner from 'services/eds/hardwareSigner';
import {CA, ISignerConfig, ISignerCreation} from "./common";
import {KEY_TYPES} from "../../consts";

type Config = ISignerConfig & { type?: KEY_TYPES };
type SignerState = Record<KEY_TYPES, {
    Signer: ISignerCreation;
    isAvailable: (c: ISignerConfig) => boolean,
    instance: HardwareSigner | FileSigner | null
}>

class EDSService {
    signers: SignerState = {
        [KEY_TYPES.FILE]: {
            Signer: FileSigner,
            isAvailable: (config: Config) => config.type === KEY_TYPES.FILE || !config.type,
            instance: null
        },
        [KEY_TYPES.HARDWARE]: {
            Signer: HardwareSigner,
            isAvailable: (config: Config) => config.type === KEY_TYPES.HARDWARE || !config.type,
            instance: null
        }
    }

    private _config: ISignerConfig = null;
    private _CA: Readonly<CA[]> = [];

    async init<T extends KEY_TYPES>(current: T): Promise<T extends KEY_TYPES.FILE ? FileSigner : HardwareSigner> {
        if (this.signers[current].instance) {
            return this.signers[current].instance as any;
        }

        const {isAvailable, Signer} = this.signers[current];

        if (!isAvailable(this._config)) {
            throw new Error('signer is disabled over config')
        }

        const signer = this.signers[current].instance = new Signer(this._config, this._CA) as any;

        await signer.init();

        console.info(`[EDS]: initialized signer of type ${current}`);

        return signer;
    }

    get CA(): Readonly<CA[]> {
        return this._CA || [];
    }

    set config(val: ISignerConfig) {
        this._config = val;
    }

    async loadEDS(remotePath?: string): Promise<void> {
        try {
            const res = await fetch(remotePath || this._config.serverListUrl);

            this._CA = await res.json();
        } catch (e) {
            console.error(e);
            console.warn(`[EDS]: can not load list of CA's`)
        }
    };
}

const service = new EDSService();

export default service;