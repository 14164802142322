import React from 'react';
import PropTypes from 'prop-types';
import setComponentsId from 'helpers/setComponentsId';
import {translate} from 'react-translate';

import {
    withStyles,
    TextField,
    MenuItem
} from '@material-ui/core';

import InputMask from 'react-input-mask';

import customInputStyle from 'assets/jss/components/customInputStyle';

const Masked: React.FC<any> = props => <InputMask {...props} maskChar="" inputRef={props.ref}/>;

Masked.propTypes = {
    ref: PropTypes.node
};

Masked.defaultProps = {
    ref: undefined
};

interface StringElementProps extends BaseProps{
    onChange(value: any): any,
    children: React.ReactNode,
    label?: string,
    enum: object,
    type: string,
    name: string,
    placeholder: string,
    select: boolean,
    sample: string,
    error: string | object,
    formControlProps: object,
    description: string,
    disabled: boolean,
    InputProps: object,
    SelectProps: object,
    mask: string,
    required: boolean,
    value: string | number
}

class StringElement extends React.Component<StringElementProps> {
    state = {value: this.props.value};

    componentWillReceiveProps(nextProps: StringElementProps) {
        const {value} = nextProps;
        if (value !== this.state.value) {
            this.setState({value});
        }
    }

    children = () => {
        const {children} = this.props;

        if (this.props.enum) {
            return Object.values(this.props.enum).map((option, key) => (
                <MenuItem key={key} value={option}>{option}</MenuItem>
            ));
        }

        return children;
    };

    render() {
        const {
            name,
            sample,
            error,
            label,
            disabled,
            InputProps,
            SelectProps,
            type,
            mask,
            required,
            placeholder,
            onChange
        } = this.props;
        const {value} = this.state;

        const select = this.props.select || !!this.props.enum;
        return (
            <TextField
                name={name}
                disabled={disabled}
                margin="normal"
                placeholder={placeholder}
                select={select}
                label={label + (required ? '*' : '')}
                value={value}
                onChange={onChange}
                helperText={!disabled && (sample || error)}
                error={!!error}
                InputProps={{...InputProps, inputComponent: Masked}}
                inputProps={{mask}}// eslint-disable-line react/jsx-no-duplicate-props
                SelectProps={SelectProps}
                type={type}
            >
                {this.children()}
            </TextField>
        );
    }
    static defaultProps: StringElementProps = {
        children: '',
        enum: null,
        type: 'string',
        name: '',
        placeholder: '',
        select: false,
        onChange: undefined,
        sample: '',
        formControlProps: {},
        error: null,
        description: '',
        disabled: false,
        InputProps: {},
        SelectProps: {},
        mask: '',
        required: false,
        setId: setComponentsId('string'),
        value: ''
    };
}


const styled = withStyles(customInputStyle)(StringElement);
export default translate('Elements')(styled);
