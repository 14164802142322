import React from 'react';

import {
    LinearProgress,
    withStyles
} from '@material-ui/core';

interface ProgressLineProps{
    loading?: boolean;
    classes: Record<string, string>;
    style?: object | null
}

const styles = {
    root: {
        height: 2,
        zIndex: 1300,
        marginBottom: -2
    },
    progress: {
        height: 2
    }
};

const ProgressLine: React.FC<ProgressLineProps> = ({ classes, loading, style }) => (
    <div className={classes.root} style={style as object}>
        {loading ? <LinearProgress className={classes.progress} /> : null}
    </div>
);

// ProgressLine.propTypes = {
//     loading: PropTypes.bool,
//     classes: PropTypes.object.isRequired,
//     style: PropTypes.object
// };

ProgressLine.defaultProps = {
    loading: false,
    style: null
};

export default withStyles(styles)(ProgressLine);
